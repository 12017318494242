<template>
  <div
    class="pieChart"
  >
    <div
      v-if="$route.path.includes('/analytics')"
    >
      <Pie
        style="height:250px;width:430px"
        :chart-options="chartOptions"
        :chart-data="chartData"
      />
    </div>
    <div v-else>
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
      />
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title, // Import the Title module
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'
import { mapActions, mapGetters } from 'vuex'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 330,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => { return [] },
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            soldItems: [],
            price: [],
          },
        ],
      },
      chartOptions: {
        plugins: {
          // Configure datalabels to display labels vertically
          datalabels: {
            align: 'start',
            anchor: 'end',
            formatter: (value, context) => {
              const label = context.chart.data.labels[context.dataIndex];
              return `${label}\n${value}%`; // Customize the label format as needed
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (context) => {
              const label = context.label || ''
              const value = context.parsed || 0
              const index = context.dataIndex
              const soldItems = this.chartData.datasets[0].soldItems[index]
              const price = this.chartData.datasets[0].price[index]

              return `${label}: ${value} (Sold Items: ${soldItems}, Price: $${price})`
            },
          },
        },
        title: {
          display: false, // Hide the title (product names)
        },
      },
    }
  },
  computed: {
    ...mapGetters(['getMostSoldProducts']),
  },
  watch: {
    getMostSoldProducts() {
      this.updateChartData()
    },
  },
  mounted() {
    this.updateChartData()
  },
  methods: {
    ...mapActions(['most_Sold_Products']),
    updateChartData() {
      const mostSoldProducts = this.getMostSoldProducts
      const labels = []
      const backgroundColors = []
      const data = []

      mostSoldProducts.forEach((product) => {
        const { articleNumber, soldItems: sold, price: productPrice } = product
        labels.push(articleNumber)
        backgroundColors.push(this.getRandomColor())
        data.push({
          value: sold,
          soldItems: sold,
          price: productPrice,
        })
      })

      this.chartData.labels = labels
      this.chartData.datasets = [
        {
          backgroundColor: backgroundColors,
          data,
        },
      ]
    },

    getRandomColor() {
      // Generate a random hexadecimal color
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    },
  },
}
</script>

<style scoped>
.pieChart{
  width: 100%;
  margin-top: 50px;
  padding-left: -60px;
}

@media screen and (min-width: 1400px){
  .pieChart{
    margin-top: 50px;
  }
}

@media screen and (max-width: 1399px){
  .pieChart{
    width: 90%;
    margin-top: 50px;
    margin-left: 52px;
  }
}
/* @media screen and (max-width: 1300px){
  .pieChart{
    margin-top: 50px;
    margin-left: -20px;
  }
} */

@media screen and (max-width: 1300px){
  .pieChart{
    width: 90%;
    margin-left: 50px;
  }
}

@media screen and (max-width: 1150px){
  .pieChart{
    margin-top: 50px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 1090px){
  .pieChart{
    width: 90%;
    margin-top: 50px;
    margin-left: 50px;
  }
}



</style>
