import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.vacationManagementDomain;
const domain2 = api.logisticStoreData;

const state = {
  status: false,
  canton: null,
  vacationRequests: [],
  hasVacation: false,
  remainingDays: null,
  ourBankHolidays: [],
  saturdayWork: {},
};

const getters = {
  getBankHolidays: (state) => {
    return state.ourBankHolidays
  },
  getCantons: (state) => {
    return state.canton;
  },
  getHasVacation: (state) => {
    return state.hasVacation;
  },
  getVacationRequests: (state) => {
    return state.vacationRequests;
  },
  getRemainingDays: (state) => {
    return state.remainingDays;
  },
  getActiveVacationRequests: (state) => {
    return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
      return vacation.cancelled == false && new Date(vacation.endVacation) >= new Date();
    });
  },
  getInActiveVacationRequests: (state) => {
    return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
      return vacation.cancelled == true || new Date(vacation.endVacation) <= new Date();
    });
  },
  getPastAndFutureVacations: (state) => {
    return state.vacationRequests.vacationRequestDtos.filter((vacation) => {
      return vacation.cancelled == false
    });
  },
  getSaturdayWork: (state) => {
    return state.saturdayWork
  },
};

const mutations = {
  SET_BANKHOLIDAYS(state, payload) {
    state.ourBankHolidays = payload;
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CANTON(state, latest) {
    state.canton = latest;
  },
  SET_VACATION_REQUESTS(state, latest) {
    state.vacationRequests = latest;
  },
  SET_ALREADY_REQUESTED_VACATION(state, latest) {
    state.hasVacation = latest;
  },
  SET_REMAINING_DAYS(state, latest) {
    state.remainingDays = latest;
  },
  SET_SATURDAY_WORK(state, latest) {
    state.saturdayWork = latest
  },
};

const actions = {
  async loadCountryStates({ commit }) {
    commit('SET_IS_LOADING', false);
    const countryid = '9080b1da-4da4-4b25-5c93-08db31f6edb2';
    try {
      const response = await axios.get(`${domain}/countryStates?countryId=${countryid}`);
      commit('SET_CANTON', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadBankHolidays({ commit }, { countryStateId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/bankHolidays`, {
        params: {
          countryStateId,
          year,
        },
      });
      commit('SET_BANKHOLIDAYS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async bankHolidays({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addBankHolidays`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank holiday added successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', false);
  },
  async copyBankHolidays({ commit },
    { countryStateId, year, successCallback }) {
    commit('SET_IS_LOADING', false);

    try {
      await axios.post(`${domain}/copyBankHolidays?countryStateId=${countryStateId}&year=${year}`);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async editBankHolidays({ commit }, { bankHolidayId, object }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editBankHolidays/{id}?bankHolidayId=${bankHolidayId}`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Bank holiday edited successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async editSaturdayWork({ commit }, { id, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editSaturdayWork?id=${id}`, object);
      commit('SET_STATUS', true);
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Saturday work edited successfully',
      //   toast: true,
      //   position: 'top-end',
      //   showConfirmButton: false,
      //   timer: 2500,
      // });
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async addSaturdayWork({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addSaturdayWork`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.Message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4500,
      });
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async leaveRequest({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addVacationRequest`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.Message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4500,
      });
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async addCoManagerToStores({ commit }, object) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/addCoManagerToStores`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getAlreadyRequestedVacation({ commit }, {
    employeeUserId, startDate, endDate,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/alreadyRequestedVacation`, {
        params: {
          employeeUserId,
          startDate,
          endDate,
        },
      })
      commit('SET_ALREADY_REQUESTED_VACATION', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadSaturdayWork({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/saturdayWork`)
      commit('SET_SATURDAY_WORK', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async cancelVacation({ commit }, vacationRequestId) {
    const params = {
      vacationRequestId,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/cancellVacationRequest`, null, { params });
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async editVacationDays({ commit }, {
    employeeUserId, numberOfDays, year, successCallback,
  }) {
    const params = {
      employeeUserId,
      numberOfDays,
      year,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editVacationDays`, null, { params });
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback(); // Call the successCallback if it exists
  },
  async moveVacationDays({ commit }, {
    employeeUserId, currentYear, newYear, amountOfDays, successCallback,
  }) {
    const params = {
      employeeUserId,
      currentYear,
      newYear,
      amountOfDays,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/moveVacationDays`, null, { params });
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback(); // Call the successCallback if it exists
  },
  async payVacationDays({ commit }, {
    employeeUserId, numberOfDays, year, successCallback,
  }) {
    const params = {
      employeeUserId,
      numberOfDays,
      year,
    };
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/payVacationDays`, null, { params });
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback(); // Call the successCallback if it exists
  },
  async loadVacationRequests({ commit }, { employeeId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/vocationRequests`, {
        params: {
          employeeId,
          year,
        },
      });
      commit('SET_VACATION_REQUESTS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async remainingDaysOfEmployee({ commit }, { employeeUserId, year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/remainingDaysOfEmployee`, {
        params: {
          employeeUserId,
          year,
        },
      });
      commit('SET_REMAINING_DAYS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
