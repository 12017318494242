var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$route.path.includes('login'))?_c('section',{staticClass:"navbar"},[(!_vm.$route.path.includes('login'))?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(!_vm.$route.path.includes('login'))?_c('b-icon-list',{staticClass:"burger-menu",attrs:{"font-scale":"2"},on:{"click":_vm.showOrHideSidebar}}):_vm._e(),_c('div',{staticStyle:{"height":"100%","padding-left":"10px","border-right":"1px solid #DDE0E5"}}),_c('div',{staticClass:"navbarParagraph"},[_c('p',[_vm._v(" "+_vm._s(_vm.navbarName)+" ")])])],1):_vm._e(),_c('div',{staticClass:"userInfo"},[_c('div',{staticStyle:{"width":"100%"}},[_c('Action')],1),_c('div',{staticClass:"notification-wrapper",staticStyle:{"margin-right":"20px","margin-left":"10px","border-left":"1px solid #E7E7E8","padding-left":"2%"}},[_c('Notification')],1),(
          _vm.getLoggedInUser.role !== 'TeamLeader' &&
            _vm.getLoggedInUser.role !== 'Sewer' &&
            _vm.getLoggedInUser.role !== 'Trainee' &&
            _vm.getLoggedInUser.role !== 'PatternMaker' &&
            _vm.getLoggedInUser.role !== 'OnlineSales' &&
            _vm.getLoggedInUser.role !== 'Designer' &&
            _vm.getLoggedInUser.role !== 'SalesPerson' &&
            _vm.getLoggedInUser.role !== 'Receptionist'
        )?_c('div',{staticClass:"notification-wrapper",staticStyle:{"margin-right":"20px"}},[_c('Cart')],1):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }