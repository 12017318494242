var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getIsSidebarHidden == false)?_c('section',{staticClass:"sidebar",class:{
    hide: _vm.getIsSidebarHidden == true,
    show: _vm.getIsSidebarHidden == false,
  },staticStyle:{"margin-top":"0rem","box-shadow":"none","padding-bottom":"0%","padding-top":"0%"}},[_c('div',{staticClass:"allButtons"},[_c('div',[_c('b-list-group',[_c('b-list-group-item',{staticStyle:{"border-bottom":"1px solid #DDE0E5 !important","height":"60px"},attrs:{"button":""}},[_c('router-link',{staticStyle:{"background-color":"white !important"},attrs:{"to":"/"}},[_c('img',{staticClass:"image1",attrs:{"src":require("@/assets/images/wlogo.svg"),"width":"29px","alt":"test"}}),_c('img',{staticClass:"image2",attrs:{"src":require("@/assets/images/waloneName.svg"),"width":"200px","alt":"test"}})])],1),(
            _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
          )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Dashboard")))])])],1):_vm._e(),(
            _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-88",modifiers:{"collapse-88":true}}],attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/sell-products' || _vm.$route.path === '/create-order'},attrs:{"to":"/create-order-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/createorder.svg"),"width":"30px","alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Storefront")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-88","accordion":"my-accordion"}},[_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.resetTotalStock}},[_c('router-link',{attrs:{"to":"/create-order"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("CreateOrder")))])])],1),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/sell-products"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("SellProductsFromStock")))])])],1)],1),(
            _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Deliverer'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-126",modifiers:{"collapse-126":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/create-order-nominative' || _vm.$route.path === '/orders-nominative' || _vm.$route.path === '/materials-overview' || _vm.$route.path === '/selling-report' || _vm.$route.path === '/materials-management'},attrs:{"to":"/create-fabric-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/material.svg"),"width":"30px","alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("CreateFabricOrder")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-126","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'Security' &&
                _vm.getLoggedInUser.role !== 'Chauffer' &&
                _vm.getLoggedInUser.role !== 'CleaningPerson' &&
                _vm.getLoggedInUser.role !== 'HR' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'HRFinance' &&
                _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/create-order-nominative"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("SellFabric")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'Security' &&
                _vm.getLoggedInUser.role !== 'Chauffer' &&
                _vm.getLoggedInUser.role !== 'CleaningPerson' &&
                _vm.getLoggedInUser.role !== 'HR' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'HRFinance' &&
                _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/orders-nominative"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("FabricOrders")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'Security' &&
                _vm.getLoggedInUser.role !== 'Chauffer' &&
                _vm.getLoggedInUser.role !== 'CleaningPerson' &&
                _vm.getLoggedInUser.role !== 'HR' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'HRFinance' &&
                _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/materials-overview"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("MaterialsOverview")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'Security' &&
                _vm.getLoggedInUser.role !== 'Chauffer' &&
                _vm.getLoggedInUser.role !== 'CleaningPerson' &&
                _vm.getLoggedInUser.role !== 'HR' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'HRFinance' &&
                _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/materials-management"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("MaterialsManagement")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'Security' &&
                _vm.getLoggedInUser.role !== 'Chauffer' &&
                _vm.getLoggedInUser.role !== 'CleaningPerson' &&
                _vm.getLoggedInUser.role !== 'HR' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'HRFinance' &&
                _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/selling-report"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("SellingReport")))])])],1):_vm._e()],1),(
            _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Deliverer'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-12",modifiers:{"collapse-12":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/orders-management' || _vm.$route.path === '/new-orders-edit' || _vm.$route.path === '/production-management' || _vm.$route.path === '/order-item-tracking' || _vm.$route.path === '/admin' || _vm.$route.path === '/deliverer-orders-management/ReadyToDeliver' || _vm.$route.path === '/orders-overview/ReadyToDeliver' || _vm.$route.path === '/orders-overview/Assigned' || _vm.$route.path === '/orders-overview/all' || _vm.$route.path === '/order-item-tracking' || _vm.$route.path === '/selling' || _vm.$route.path === '/return-process' || _vm.$route.path === '/internal-moving'},attrs:{"to":"/orders-management-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/ordermanagement.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("OrdersManagement")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-12","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'Receptionist' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&

                _vm.getLoggedInUser.role !== 'OnlineSales'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.resetCreatedOrdersByOrderNumber}},[_c('router-link',{attrs:{"to":"/orders-management"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("NewOrders"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role == 'Admin' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/new-orders-edit"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("EditNewOrders")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'Receptionist' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'OnlineSales'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/production-management"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ProductionManagement"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&

                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'OnlineSales'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/deliverer-orders-management/ReadyToDeliver"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('DelivererOrdersManagement'))+" ")])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'PatternMaker'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":_vm.getLoggedInUser.role == 'Deliverer'
                  ? '/orders-overview/ReadyToDeliver'
                  : _vm.getLoggedInUser.role == 'TeamLeader'
                    ? '/orders-overview/Assigned'
                    : '/orders-overview/all'},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("OrdersOverview"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'Receptionist' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                _vm.getLoggedInUser.role !== 'OnlineSales' &&
                _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/order-item-tracking"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("OrderItemTracking")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role == 'Admin' ||
                _vm.getLoggedInUser.role == 'Manager'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/admin"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("AdminAuthorise"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role == 'Admin'||
                _vm.getLoggedInUser.role == 'Manager'||
                _vm.getLoggedInUser.role == 'StockManagerWarehousProductions'||
                _vm.getLoggedInUser.role == 'Receptionist'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/selling"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("SellingReport"))+" ")])])],1):_vm._e(),_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.resetInternal}},[_c('router-link',{attrs:{"to":"/internal-moving"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("InternalMoving"))+" ")])])],1),_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.resetInternal}},[_c('router-link',{attrs:{"to":"/return-process"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ReturnToStock"))+" ")])])],1)],1),(
            _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-3",modifiers:{"collapse-3":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/products' || _vm.$route.path === '/product-reporting' || _vm.$route.path === '/product-configuration'},attrs:{"to":"/products-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/products.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Products1")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-3","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'ExecutiveAssitant'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.reset}},[_c('router-link',{attrs:{"to":"/products"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ProductManagement"))+" ")])])],1):_vm._e(),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-reporting"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ProductReporting"))+" ")])])],1),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-configuration"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ConfigurationsProducts"))+" ")])])],1)],1),(_vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' && _vm.getLoggedInUser.role !== 'Sewer'
            && _vm.getLoggedInUser.role !== 'PatternMaker' &&
            _vm.getLoggedInUser.role !== 'Accountant'&&
            _vm.getLoggedInUser.role !== 'StockManager' &&
            _vm.client != 'DrenushaXharra' &&
            _vm.getLoggedInUser.role !== 'HR' &&
            _vm.getLoggedInUser.role !== 'HRFinance' &&
            _vm.getLoggedInUser.role !== 'Security' &&
            _vm.getLoggedInUser.role !== 'Chauffer' &&
            _vm.getLoggedInUser.role !== 'CleaningPerson' &&
            _vm.getLoggedInUser.role !== 'TeamLeader' &&
            _vm.getLoggedInUser.role !== 'Deliverer' &&
            _vm.getLoggedInUser.role !== 'TeamLeaderPattern')?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-7",modifiers:{"collapse-7":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/status-in-stock' || _vm.$route.path === '/product-in-stock' || _vm.$route.path === '/products-in-stock' || _vm.$route.path === '/product-management-in-stock' || _vm.$route.path === '/product-overview-in-stock' || _vm.$route.path === '/product-in-stock-track-trace' || _vm.$route.path === '/products-stock' || _vm.$route.path === '/internal-moving-stock'},attrs:{"to":"/status-in-stock"}},[_c('img',{attrs:{"src":require("@/assets/images/stock.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("StockinStore")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-7","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'Manager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'OnlineSales'

            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-in-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Productionmerch"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Manager' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/products-in-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("MerchInStore"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.resetTotalStock}},[_c('router-link',{attrs:{"to":"/products-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ProductsInStock"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'Designer' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'PatternMaker'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-management-in-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Merchproductionoverview"))+" ")])])],1):_vm._e(),(false)?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-overview-in-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ProductOverviewInStock"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Designer' &&
                _vm.getLoggedInUser.role !== 'Manager' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
                _vm.getLoggedInUser.role !== 'OnlineSales'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-in-stock-track-trace"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Merchproductiontracking"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant'
            )?_c('b-list-group-item',{attrs:{"button":""},on:{"click":_vm.resetInternal}},[_c('router-link',{attrs:{"to":"/internal-moving-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("InternalMoving"))+" ")])])],1):_vm._e()],1),(_vm.getLoggedInUser.role !== 'Deliverer' && _vm.getLoggedInUser.role !== 'Receptionist' && _vm.getLoggedInUser.role !== 'StockManager' &&
            _vm.getLoggedInUser.role !== 'Accountant' && _vm.getLoggedInUser.role !== 'TeamLeader' &&
            _vm.getLoggedInUser.role !== 'HR' &&
            _vm.getLoggedInUser.role !== 'HRFinance' &&
            _vm.getLoggedInUser.role !== 'Security' &&
            _vm.getLoggedInUser.role !== 'Chauffer' &&
            _vm.getLoggedInUser.role !== 'CleaningPerson' &&
            _vm.getLoggedInUser.role !== 'SalesPerson' &&
            _vm.getLoggedInUser.role !== 'TeamLeaderPattern' &&
            _vm.getLoggedInUser.role !== 'Deliverer')?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/inventory' || _vm.$route.path === '/inventory-reporting' || _vm.$route.path === '/inventory-overview' || _vm.$route.path === '/material-colors' || _vm.$route.path === '/cart'},attrs:{"to":"/material-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/material.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t('Inventory')))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-2","role":"tabpanel","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'OnlineSales' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'Receptionist' &&
                _vm.getLoggedInUser.role !== 'ExecutiveAssitant'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/inventory"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("InventoryManagement"))+" ")])])],1):_vm._e(),(_vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/inventory-reporting"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("InventoryReporting"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Receptionist')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/inventory-overview"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("InventoryOverview"))+" ")])])],1):_vm._e(),(_vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' && _vm.getLoggedInUser.role !== 'Receptionist')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/material-colors"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("MaterialColors"))+" ")])])],1):_vm._e(),(_vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' && _vm.getLoggedInUser.role !== 'Receptionist' &&
              _vm.getLoggedInUser.role !== 'ExecutiveAssitant')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/cart"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("Cart"))+" ")])])],1):_vm._e()],1),(
            _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Receptionist' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern')?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-4",modifiers:{"collapse-4":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/teams' || _vm.$route.path === '/users' || _vm.$route.path === '/leave-request' || _vm.$route.path === '/calendar' || _vm.$route.path === '/employee-feedback' || _vm.$route.path === '/bankholidays'},attrs:{"to":"/hr-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/teams.svg"),"width":"30px","alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("HumanResources")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-4","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'Accountant' &&
                _vm.getLoggedInUser.role !== 'SalesPerson' &&
                _vm.getLoggedInUser.role !== 'Designer')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/teams"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("TeamsManagement"))+" ")])])],1):_vm._e(),(_vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'ExecutiveAssitant')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/users"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("EmployeeManagement"))+" ")])])],1):_vm._e(),(_vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'ExecutiveAssitant')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/leave-request"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("VacationManagement"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role == 'Admin' ||
                _vm.getLoggedInUser.role == 'ExecutiveAssitant'||
                _vm.getLoggedInUser.role == 'HRFinance' ||
                _vm.getLoggedInUser.role == 'HR')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/calendar"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("VacationPlan")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role == 'Admin' ||
                _vm.getLoggedInUser.role == 'HRFinance' ||
                _vm.getLoggedInUser.role == 'Designer' ||
                _vm.getLoggedInUser.role == 'HR'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/employee-feedback"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Feedback")))])])],1):_vm._e(),(_vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'ExecutiveAssitant')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/bankholidays"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("BankHolidays"))+" ")])])],1):_vm._e()],1),(_vm.getLoggedInUser.role == 'CEO' || _vm.getLoggedInUser.role == 'Admin' || _vm.getLoggedInUser.role == 'HRFinance')?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-8",modifiers:{"collapse-8":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/employee-payments' || _vm.$route.path === '/finance-account' || _vm.$route.path === '/finance-report' },attrs:{"to":"/finance-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/finance.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Finance")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-8","accordion":"my-accordion"}},[_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/finance-report"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("FinancialReport")))])])],1),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/employee-payments"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Salary")))])])],1),_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/finance-account"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Configurations")))])])],1)],1),(
            _vm.client != 'DrenushaXharra' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern')?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-13",modifiers:{"collapse-13":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/location' || _vm.$route.path === '/order-item-location' || _vm.$route.path === '/product-in-stock-location' || _vm.$route.path === '/material-variant-location'},attrs:{"to":"/warehouse-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/warehouses.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("WareHouse")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-13","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.getLoggedInUser.role !== 'Receptionist' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.getLoggedInUser.role !== 'StockManager')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/location"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Location")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'Deliverer' &&
                _vm.getLoggedInUser.role !== 'Sewer' &&
                _vm.getLoggedInUser.role !== 'Trainee' &&
                _vm.getLoggedInUser.role !== 'PatternMaker' &&
                _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
                _vm.client != 'DrenushaXharra'
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/order-item-location"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("OrderItemLocation")))])])],1):_vm._e(),(
              (_vm.getLoggedInUser.role == 'Receptionist' ||
                _vm.getLoggedInUser.role == 'TeamLeader' ||
                _vm.getLoggedInUser.role == 'Manager' ||
                _vm.getLoggedInUser.role == 'CEO' ||
                _vm.getLoggedInUser.role != 'StockManagerMaterialAndProduct' ||
                _vm.getLoggedInUser.role == 'Admin') && _vm.client != 'DrenushaXharra'

            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/product-in-stock-location"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("ProductOverviewInStock"))+" ")])])],1):_vm._e(),(
              _vm.getLoggedInUser.role !== 'StockManager' &&
                _vm.getLoggedInUser.role !== 'Receptionist' &&
                _vm.getLoggedInUser.role !== 'TeamLeader' &&
                _vm.client != 'DrenushaXharra')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/material-variant-location"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("MaterialVariantLocation"))+" ")])])],1):_vm._e()],1),(
            _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Receptionist' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
          )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/suppliers"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/supplier.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Suppliers")))])])],1):_vm._e(),(
            _vm.getLoggedInUser.role == 'hellotest' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant'
          )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/history-production"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/8. History of Production (1).svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("HistoryOfProduction")))])])],1):_vm._e(),(
            _vm.getLoggedInUser.role == 'Admin' || _vm.getLoggedInUser.role == 'SalesPerson' || _vm.getLoggedInUser.role == 'Designer' || _vm.getLoggedInUser.role == 'Manager' || _vm.getLoggedInUser.role == 'Receptionist' || _vm.getLoggedInUser.role == 'CEO' || (_vm.getLoggedInUser.role == 'SalesPerson' && _vm.client == 'DrenushaXharra')
          )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-5",modifiers:{"collapse-5":true}}],attrs:{"button":""}},[_c('router-link',{class:{ 'active' : _vm.$route.path === '/clients' || _vm.$route.path === '/campaign'},attrs:{"to":"/clients-dashboard"}},[_c('img',{attrs:{"src":require("@/assets/images/clients.svg"),"alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Clients")))])]),_c('div',{staticClass:"collapse-icon"},[_c('b-icon-chevron-down')],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-5","accordion":"my-accordion"}},[(
              _vm.getLoggedInUser.role == 'Admin' || _vm.getLoggedInUser.role == 'SalesPerson' || _vm.getLoggedInUser.role == 'Designer' || _vm.getLoggedInUser.role == 'Manager' || _vm.getLoggedInUser.role == 'Receptionist' || _vm.getLoggedInUser.role == 'CEO' || (_vm.getLoggedInUser.role == 'SalesPerson' && _vm.client == 'DrenushaXharra')
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/clients"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("ClientsManagement")))])])],1):_vm._e(),(
              _vm.getLoggedInUser.role == 'Admin' || _vm.getLoggedInUser.role == 'SalesPerson' || _vm.getLoggedInUser.role == 'Designer' || _vm.getLoggedInUser.role == 'Manager' || _vm.getLoggedInUser.role == 'Receptionist' || _vm.getLoggedInUser.role == 'CEO' || (_vm.getLoggedInUser.role == 'SalesPerson' && _vm.client == 'DrenushaXharra')
            )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/campaign"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',[_vm._v(_vm._s(_vm.$t("Campaign")))])])],1):_vm._e()],1),(
            _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'Sewer' &&
              _vm.getLoggedInUser.role !== 'Trainee' &&
              _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Receptionist' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialAndProduct' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'TeamLeaderPattern'
          )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/analytics"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/analytics.png"),"width":"30px","alt":"test"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Analytics")))])])],1):_vm._e()],1)],1),_c('b-list-group',{staticClass:"settings"},[_c('b-list-group-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-personal-info",modifiers:{"modal-personal-info":true}}]},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg"),"width":"30px","alt":"test"}}),_c('p',{staticStyle:{"margin-left":"10px","font-size":"16px","font-weight":"400"}},[_vm._v(_vm._s(_vm.$t("Settings")))])])],1)],1)],1),_c('Personal')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }