<template>

  <div
    style=" display: flex;width: 100%; justify-content: space-between;margin-left: 10px; height: 0vh; margin-top: 15px;"
  >

    <b-container>
      <b-row>
        <b-col cols="12">
          <carousel :per-page="1">
            <slide class="p-2">
              <div style="background-color: #242F6E; color: white; display: flex; justify-content: space-evenly; height: 25vh; border-radius: 10px; align-items: center;">
                <div
                  class="tableicons"
                  @click="goToClients"
                >
                  <img
                    src="@/assets//images/Icons for SPIM Sidebar-58.svg"
                    alt=""
                  >
                  <p
                    style="margin-top: 20px;"
                  >{{ getClientCounter ? getClientCounter[0].totalCount : null }}</p>
                  <p>{{ $t('Clients') }}</p>
                </div>
                <div
                  class="tableicons"
                  @click="goToClients"
                >
                  <img
                    src="@/assets//images/Icons for SPIM Sidebar-53.svg"
                    alt=""
                  >
                  <p
                    style="margin-top: 20px;"
                  >{{ getClientCounter ? getClientCounter[1].totalCount : null }}</p>
                  <p
                    style="width: 100px;"
                  >{{ $t('Leads') }}</p>
                </div>

                <div
                  v-if="getLoggedInUser.role != 'TeamLeader'"
                  style="background-color: white; height: 170px;width: 5px; border-radius: 5px;"
                />


                <div
                  v-if="getLoggedInUser.role != 'TeamLeader'"
                  class="tableicons"
                >
                  <img
                    src="@/assets//images/Icons for SPIM Sidebar-54.svg"
                    alt=""
                  >
                  <!-- style="margin-top: 20px;" -->
                  <!-- <p
                          style="margin-top: 20px;"
                        >
                          {{ getdashboardCounterItems.productInStockSell }}</p> -->
                  <p
                    style="margin-top: 20px;"
                  >{{ numbersWithcomma(getdashboardCounterItems.orderItemTotalPrice) }} €</p>
                  <p>{{ $t('TotalSells') }}</p>
                </div>
                <div
                  v-if="getLoggedInUser.role != 'TeamLeader'"
                  class="tableicons"

                  @click="goToProducts"
                >
                  <img
                    src="@/assets//images/Icons for SPIM Sidebar-55.svg"
                    alt=""
                  >

                  <p
                    style="margin-top: 20px;"
                  >
                    {{ numbersWithcomma(getdashboardCounterItems.orderItemTotalPaid) }} €</p>
                  <p>
                    {{ $t('TotalPaid') }}
                  </p>
                </div>


              </div>
              <!-- </b-card> -->
            </slide>
            <!-- <slide class="p-2">

                    <div style="background-color: #242F6E; color: white; display: flex; justify-content: space-evenly; height: 25vh; border-radius: 10px; align-items: center;">
                      <div
                        class="tableicons"
                        @click="goToProductsInStock"
                      >
                        <img
                          src="./../assets//images/Icons for SPIM Sidebar-56.svg"
                          alt=""
                        >

                        <p
                          style="margin-top: 20px;"
                        >
                          {{ getdashboardCounterItems.productInStock }}</p>
                        <p>
                          {{ $t('ProdStock') }}
                        </p>
                      </div>
                      <div style="background-color: white; height: 170px;width: 5px; border-radius: 5px;" />
                      <div
                        class="tableicons"
                        @click="goToProductsInStock"
                      >
                        <img
                          src="./../assets//images/Icons for SPIM Sidebar-57.svg"
                          alt=""
                        >
                        <p
                          style="margin-top: 20px;"
                        >55</p>
                        <p>{{ $t('SoldProd') }}</p>
                      </div>

                    </div>
                  </slide> -->
          </carousel>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div
            style="width: 73%; background-color: rgb(207, 205, 88);
              border-radius: 10px; margin-left: 10px;"
          >
            <PieChart />
          </div> -->
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';
import connection from '@/microsoft-signalR/signalR';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';


export default {
  components: {

    Carousel,
    Slide,
  },
  data() {
    return {
      loading: false,
      orderGroup: {},
      rating: 7,
      showPieChart: false,
      itemsPerPage: 4,
      buttons: [
        { text: this.$t('Daily'), days: 1 },
        { text: this.$t('Week'), days: 7 },
        { text: this.$t('Month'), days: 30 },
      ],
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        arrows: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

        // responsive: [
        //   {
        //     breakpoint: 1024,

        //     settings: {
        //       slidesToShow: 3,

        //       slidesToScroll: 3,

        //       infinite: true,

        //       dots: true,
        //     },
        //   },
        //   {
        //     breakpoint: 390,

        //     settings: {
        //       slidesToShow: 2,

        //       slidesToScroll: 2,

        //       infinite: true,

        //       dots: true,
        //       arrows: false,
        //     },
        //   },

        //   {
        //     breakpoint: 600,

        //     settings: {
        //       slidesToShow: 2,

        //       slidesToScroll: 2,

        //       initialSlide: 2,
        //       arrows: false,
        //     },
        //   },

        //   {
        //     breakpoint: 415,

        //     settings: {
        //       slidesToShow: 2,

        //       slidesToScroll: 2,
        //       arrows: false,
        //     },
        //   },
        //   {
        //     breakpoint: 768,

        //     settings: {
        //       slidesToShow: 3,

        //       slidesToScroll: 3,
        //       arrows: false,
        //     },
        //   },
        // ],
      },
      currentPage: 1,
      selectedOrderState: this.$t('All'),
      pickedDate: null,
      year: 2023,
      yearsArray: [],
      options1: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      visibleDiv: 'monthly',
      pageNumber: 1,
      pageSize: 4,
      stateOptions: [
        { value: null, label: this.$t('All') },
        { value: 'Created', label: this.$t('Created') },
        { value: 'Assigned', label: this.$t('Assigned') },
        { value: 'Confirmed', label: this.$t('Confirmed') },
        { value: 'InProduction', label: this.$t('InProduction') },
        { value: 'InSewing', label: this.$t('InSewing') },
        { value: 'InHandSewing', label: this.$t('InHandSewing') },
        { value: 'QualityPassed', label: this.$t('QualityPassed') },
        { value: 'ReadyToDeliver', label: this.$t('ReadyToDeliver') },
        { value: 'Delivered', label: this.$t('Delivered') },
        { value: 'StockNotAvailable', label: this.$t('StockNotAvailable') },
        { value: 'QualityCheckNotPassed', label: this.$t('QualityCheckNotPassed') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getLanguage', 'getdashboardCounterItems', 'getUsers', 'getFeedbackForDashboard', 'getClientCounter', 'getStockMaterialVariantReporting', 'getOrdersGrouppedByStateDashboard', 'getOrdersGrouppedByState', 'getLoggedInUser', 'getIsLoading', 'getUpComingPickUpDates', 'getTotalItemsForOrders', 'getMostSoldProducts']),
    colorByRate() {
      if (this.rating <= 3) {
        return 'red';
      }
      if (this.rating > 3 && this.rating <= 6) {
        return 'orange';
      }
      return 'green';
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getStockMaterialVariantReporting.slice(start, end);
    },
    paginatedDataForFeedback() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getFeedbackForDashboard.slice(start, end);
    },
  },
  watch: {
    pageNumber(value) {
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate != null ? this.pickedDate : 7,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    year(value) {
      this.dashboard_Yearly_Graph({
        year: value,
      })
    },
  },
  created() {
    this.connectToSignalR();
  },
  mounted() {
    this.fetch()
    this.most_Sold_Products()
    this.upcomingPickupDays({
      numberOfDays: 5,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    })
    this.loadClientCounter()
    this.dashboardCounterItemsAndProductInStock()

    const currentYear = new Date().getFullYear();

    // Create an array with the last year, current year, and next 5 years
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }

    // Set the selected year to the current year
    this.year = currentYear;

    // console.log('most sold', this.getMostSoldProducts)
  },
  methods: {
    ...mapActions(['loadOrdersGrouppedByState',
      'dashboard_Yearly_Graph',
      'reporting_less_stockMaterialVariants', 'loadOrdersGrouppedByState', 'loadFeedbackForDashboard',
      'changeLoadingtoTrue', 'resetOrdersOverview', 'upcomingPickupDays', 'loadOrdersGrouppedByState', 'most_Sold_Products', 'loadClientCounter', 'dashboardCounterItemsAndProductInStock', 'getUsersPagination']),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },

    top10() {
      this.reporting_less_stockMaterialVariants()
    },
    goToOrder() {
      this.$router.push('orders-overview/all')
    },
    goToMaterials() {
      this.$router.push('inventory')
    },
    goToFeedbacks() {
      this.$router.push('employee-payments')
    },
    goToClients() {
      this.$router.push('clients')
    },
    goToProducts() {
      this.$router.push('products')
    },
    goToProductsInStock() {
      this.$router.push('products-in-stock')
    },
    feedbackForDashboard() {
      this.loadFeedbackForDashboard()
    },
    updateShowPieChart() {
      this.showPieChart = true; // Update showPieChart to true when PieChart is displayed
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      const totalPages = Math.ceil(this.getStockMaterialVariantReporting.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    prevPageFeedback() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPagefeedback() {
      const totalPages = Math.ceil(this.getFeedbackForDashboard.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        // console.log('Connection to User Hub Successful');
      }
      function rejected() {
        //      console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        setTimeout(() => {
          connection.start().then(fulfilled, rejected);
        }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },

    disconnectFromSignalR() {
      connection.stop();
    },

    async fetch() {
      // this.changeLoadingtoTrue(false)

      try {
        await this.loadOrdersGrouppedByState()
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    handleInput() {
      // Remove any non-digit characters
      this.year = this.year.replace(/\D/g, '');

      // Limit the year to 4 digits
      this.year = this.year.slice(0, 4);
    },
    showDiv(divId) {
      this.visibleDiv = divId;
    },
    pickUpDates(index) {
      this.activeButton = index; // Set the active button
      this.pickedDate = this.buttons[index].days; // Set the pickedDate
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate, // Use the pickedDate value
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
  },


};
</script>

<style lang="scss" scoped>

.main{
  // padding: 70px 15px 0px 105px;
  display: flex;
  flex-direction: column;
}

.card-content h6{
  font-size: 15px;
}

.stateChart{
  width: 100%;
  display: flex;
}

.line{
  width: 50%;
}

.tableicons{
  text-align: center;
}

.tableicons img{
  width: 90px;
}

.blue{
  background-color: #242f6e;
  border-color: #242f6e;
  color: white;
 }

 .green{
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
 }
 .red{
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
 }

.tableLeads{
  display: flex;
  width: 100%;
}

.chart{
  width: 99%;
}

.nav-tabs .nav-item .nav-link {
    background-color: transparent !important; /* Remove default background */
  }

  .nav-tabs .nav-item.active .nav-link {
    background-color: red !important; /* Add your custom background color */
    color: #ffffff !important; /* Text color for the active tab */
  }

.row{
  // margin-top:100px;
}

.custom-carousel .carousel-indicators {
  display: none !important;
}
.slider{
  width: 100%;
}

.slick-slide slick-active slick-current{
  width: 100%
}

.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}
.slick-track{
  width: 100%
}

.mainPagination{
  font-size: 12px;
}


.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}

.b-tabs .nav-item .nav-link {
  background-color: rgb(80, 80, 86) !important;
  color: white;
}
.b-tabs .nav-link.active {
  background-color: rgb(99, 99, 113) !important;
}

.light-card__wrapper {
  padding: 0 0px 15px 0px;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  margin-left: 8px;
  margin-right: 8px;
  grid-row-gap: 12px;
  background-color: #242F6E;
    padding: 22px;
    border-radius: 10px;
}
.upcomingLowest{
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-evenly;
}

.barPieChart{
  display: flex;
}

.main__title{
  margin-bottom: 30px;
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
}
 .hide {
  display: none;
 }
 .blue{
  background-color: #242f6e;
  border-color: #242f6e;
  color: white;
 }
//  .container{
//   margin: 10px;
//   padding: 0px;
//  }
 .blue2{
//  border-top: 4px solid #242f6e;
  // background: linear-gradient(356deg, #15E582 0%, #000000 100%);
  // background-clip: padding-box;
  border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #128EEE 70% , #424242 );
}
.green2{
//  border-top: 4px solid #0cb893;
 border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #15E582  70% , #424242 );
}

.buttonTime{
  background: #FF274F !important;
    color: #f4f1ed;
    border-color: #262E6C;
}

.buttons{
  width: 50%
}
.red2{
//  border-top: 4px solid #FF274F;
border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #F11313   70% , #424242 );
}
 .green{
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
 }
 .red{
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
 }
 .light-card {
        overflow-wrap: anywhere;
  }

  .team_table{
    box-shadow: none;
    background-color: white;
  }

@media screen and (min-width: 1400){
  .chart{
    width: 100% !important;
  }
}

@media screen and (min-width: 1341px) and (max-width: 1650px){
  .card-content h6{
    font-size: 13px;
  }
  .tableicons img{
    width: 70px !important;
  }
}

 @media screen and (max-width: 1340px) {
  .main {
    padding: 180px 0 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table{
      width: 90%;
    }

    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination{
      font-size: 12px;
      width: 90%;
    }

    .tableicons img{
      width: 80px;
    }
  }
  .stateChart{
    width: 100%;
    display: block;
  }
  .line{
    width: 90%;
    margin-top: 30px;
    margin-left: 50px;
  }
  .tableLeads{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 1100px){
  .main{
    padding: 140px 39px 0px 40px !important;
  }
}

 @media screen and (max-width: 800px) {
  .main {
    padding: 130px 25px 0px 25px !important;
    .container {
      .light-card__wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
        margin-left: 8px;
        margin-right: 8px;
      }

      .bar-pie-chart{
        display: block;
      }

      .light-card {
        overflow-wrap: anywhere;
      }
    }
    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .main__title{
      margin-bottom: 10px;
      font-size: 30px;
      margin-top: 50px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1340px) {
  .main {
    padding: 180px 0 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table {
      width: 90%;
    }

    .upcomingLowest {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination {
      font-size: 12px;
      width: 90%;
    }
  }
}

@media screen and (max-width: 1190px){
  .main{
    padding: 130px 40px 0 40px !important;
  }
}
.active-button {
  background: transparent linear-gradient(52deg, #f7f7f7 0%, #fbfbfb 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
    color: #262E6C !important;
    border-color: transparent;
}

</style>
