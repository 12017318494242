<template>
  <div
    style="padding-top: 50px;"
  >
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { mapActions, mapGetters } from 'vuex'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator,
  },
  props: ['year'],
  data() {
    return {
      chartData: {
        labels: [], // Your X-axis labels
        datasets: [
          {
            label: 'In Store', // Label for the first line
            backgroundColor: '#eb3449',
            borderColor: 'red', // Change the line color here
            borderWidth: 3,
            pointBackgroundColor: '#eb3449',
            pointRadius: 5,
            data: [], // Data for the first line
          },
          {
            label: 'Online', // Label for the second line
            backgroundColor: '#242F6E', // Change the background color here
            borderColor: '#242F6E', // Change the line color here
            borderWidth: 3,
            pointBackgroundColor: '#242F6E', // Change the point color here
            pointRadius: 5,
            data: [], // Data for the second line
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 66,
            right: 66,
            top: 0,
            bottom: 0,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters(['getYearlyDashboard']),
  },
  watch: {
    getYearlyDashboard: {
      handler() {
        this.updateChartData()
      },
      immediate: true,
    },
    year(value) {
      this.dashboard_Yearly_Graph({
        year: value,
      })
    },
  },
  mounted() {
    this.dashboard_Yearly_Graph({
      year: this.year,
    })
  },
  methods: {
    ...mapActions(['dashboard_Yearly_Graph']),
    updateChartData() {
      const yearlyData = this.getYearlyDashboard;

      if (yearlyData && yearlyData.length >= 2) {
        const dataset1Data = yearlyData[0];
        const dataset2Data = yearlyData[1];

        // Update labels and data for both datasets
        this.chartData.labels = Object.keys(dataset1Data);
        this.chartData.datasets[0].data = Object.values(dataset1Data);
        this.chartData.datasets[1].data = Object.values(dataset2Data);
      } else {
        // Handle the case where data is not available
        // For instance, set default values or display an error message
        console.log('Yearly data is not available or incomplete.');
      }
    },
  },
}
</script>

<style>
.my-line-chart .chartjs-render-monitor .chartjs-dataset-0 {
  border-color: red; /* Modify the line color here */
  border-width: 1px; /* Modify the line thickness here */
}
</style>
