var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getIsSidebarHidden == false)?_c('section',{staticClass:"sidebar",class:{ hide: _vm.getIsSidebarHidden == true,
            show: _vm.getIsSidebarHidden == false,
            'hovered': _vm.isHovered },staticStyle:{"margin-top":"0rem","box-shadow":"none","padding-bottom":"0%","padding-top":"0%"},on:{"mouseover":function($event){return _vm.setHovered(true)},"mouseout":function($event){return _vm.setHovered(false)}}},[_c('div',{staticClass:"allButtons"},[_c('div',[_c('b-list-group',[_c('b-list-group-item',{staticStyle:{"border-bottom":"1px solid #DDE0E5 !important","height":"60px"},attrs:{"button":""}},[_c('router-link',{staticStyle:{"background-color":"white !important","border":"none !important"},attrs:{"to":"/"}},[_c('img',{staticClass:"image1",attrs:{"src":require("@/assets/images/M-logo.svg"),"width":"40px","alt":"test"}}),_c('img',{staticClass:"image2",attrs:{"src":require("@/assets/images/SpimFashionLogo.svg"),"width":"100px","alt":"test"}})])],1),(
            _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'TeamLeader' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant' && _vm.getLoggedInUser.role !== 'Sewer'
              && _vm.getLoggedInUser.role !== 'PatternMaker' &&
              _vm.getLoggedInUser.role !== 'OnlineSales' &&
              _vm.getLoggedInUser.role !== 'Designer' &&
              _vm.getLoggedInUser.role !== 'SalesPerson' &&
              _vm.getLoggedInUser.role !== 'Accountant'&&
              _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.client != 'DrenushaXharra' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Deliverer' &&
              _vm.getLoggedInUser.role !== 'StockManagerMaterialVariant'
          )?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/products-in-stock"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("MerchInStore"))+" ")])])],1):_vm._e(),(
            _vm.getLoggedInUser.role !== 'Receptionist' && _vm.getLoggedInUser.role !== 'Deliverer' && _vm.getLoggedInUser.role !== 'Receptionist' && _vm.getLoggedInUser.role !== 'StockManager' &&
              _vm.getLoggedInUser.role !== 'Accountant' &&
              _vm.getLoggedInUser.role !== 'Security' &&
              _vm.getLoggedInUser.role !== 'Chauffer' &&
              _vm.getLoggedInUser.role !== 'CleaningPerson' &&
              _vm.getLoggedInUser.role !== 'HR' &&
              _vm.getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
              _vm.getLoggedInUser.role !== 'HRFinance' &&
              _vm.getLoggedInUser.role !== 'Deliverer')?_c('b-list-group-item',{attrs:{"button":""}},[_c('router-link',{attrs:{"to":"/inventory-overview"},nativeOn:{"click":function($event){return _vm.clickLink.apply(null, arguments)}}},[_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.$t("InventoryOverview"))+" ")])])],1):_vm._e()],1)],1),_c('b-list-group',{staticClass:"settings"},[_c('b-list-group-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-personal-info",modifiers:{"modal-personal-info":true}}]},[_c('router-link',{staticClass:"disabled-link",attrs:{"to":"/nowhere"}},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg"),"width":"30px","alt":"test"}}),_c('p',{staticStyle:{"margin-left":"10px","font-size":"16px","font-weight":"400"}},[_vm._v(_vm._s(_vm.$t("Settings")))])])],1)],1)],1),_c('Personal')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }