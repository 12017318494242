<template>
  <div>
    <section
      v-if="!$route.path.includes('login')"
      class="navbar"
    >
      <div
        v-if="!$route.path.includes('login')"
        style="display: flex; align-items: center;"
      >
        <b-icon-list
          v-if="!$route.path.includes('login')"
          font-scale="2"
          class="burger-menu"
          @click="showOrHideSidebar"
        />
        <!-- <router-link to="/">
          <b-img
            :src="require('../assets/images/fashionlogo.svg')"
            alt=""
            width="110"
          />
        </router-link> -->

        <div
          style="height: 100%;padding-left: 10px;border-right: 1px solid #DDE0E5; "
        >
          <!-- <div class="container final">
            <router-link to="/">
              <img
                src="@/assets/images/SpimFashionLogo.svg"
                width="60px"
                height="60px"
                alt="test"
              >
            </router-link>
          </div> -->

        </div>
        <div
          class="navbarParagraph"
        >
          <p>
            {{ navbarName }}
          </p>
        </div>
      </div>
      <div class="userInfo">
        <div
          style="width: 100%;"
        >
          <Action />
        </div>
        <div
          style="margin-right: 20px; margin-left: 10px; border-left: 1px solid #E7E7E8; padding-left: 2%;"
          class="notification-wrapper"
        >
          <!-- @click="notificationn" -->
          <Notification />
        </div>
        <div
          v-if="
            getLoggedInUser.role !== 'TeamLeader' &&
              getLoggedInUser.role !== 'Sewer' &&
              getLoggedInUser.role !== 'Trainee' &&
              getLoggedInUser.role !== 'PatternMaker' &&
              getLoggedInUser.role !== 'OnlineSales' &&
              getLoggedInUser.role !== 'Designer' &&
              getLoggedInUser.role !== 'SalesPerson' &&
              getLoggedInUser.role !== 'Receptionist'
          "
          style="margin-right: 20px"
          class="notification-wrapper"
        >
          <!-- @click="notificationn" -->
          <Cart />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import Cart from './cart/Cart.vue'
import Notification from './Notification.vue'
import Action from './Action.vue'

// import connection from '../microsoft-signalR/signalR'

export default {
  components: {
    Notification,
    Cart,
    Action,
  },
  // eslint-disable-next-line vue/require-prop-types
  // props: ['navbarName'],
  data() {
    return {
      notification: '',
      modalOpen: false,
      dataFromSignup: null,
      showActionDropdown: false,
      navbarName: null,
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getLanguage', 'getIsSidebarHidden']),
    language() {
      if (this.getLanguage === 'en') {
        return 'English'
      }
      return 'Shqip'
    },
  },
  watch: {
    '$route.name': function (value) {
      this.navbarName = value;
    },
  },
  mounted() {
    if (this.navbarName == null) {
      this.navbarName = this.$route.name
    }
    this.loadCartItems({ state: 'Pending' })
    gsap.registerPlugin(Flip);

    const layouts = ['final', 'plain', 'columns', 'grid'];
    const container = document.querySelector('.container');
    let curLayout = 0; // index of the current layout

    function nextState() {
      const state = Flip.getState('.letter, .for, .gsap', { props: 'color,backgroundColor', simple: true });
      // console.log('Current Layout:', curLayout);
      // console.log('State:', state);

      container.classList.remove(layouts[curLayout]);
      curLayout = (curLayout + 1) % layouts.length;
      // console.log('Next Layout:', curLayout);

      container.classList.add(layouts[curLayout]);

      Flip.from(state, {
        absolute: true,
        stagger: 0.07,
        duration: 0.7,
        ease: 'power2.inOut',
        spin: curLayout === 0,
        simple: true,
        onEnter: (elements, animation) => {
          // console.log('Entering Animation');
          return gsap.fromTo(elements, { opacity: 0 }, { opacity: 1, delay: animation.duration() - 0.1 });
        },
        onLeave: (elements) => {
          // console.log('Leaving Animation');
          return gsap.to(elements, { opacity: 0 });
        },
      });

      gsap.delayedCall(curLayout === 0 ? 3.5 : 1.5, nextState);
    }

    gsap.delayedCall(1, nextState);
  },
  methods: {
    ...mapActions(['logoutUser', 'changeLanguage', 'changeVisibilityOfSidebar', 'loadNotifications', 'loadCartItems']),
    pushHome() {
      this.$router.push('/')
    },

    openModal() {
      this.modalOpen = true;
    },
    signOut() {
      // Perform sign-out logic here
      console.log('User signed out');
    },
    changeLanguage() {
      // Perform language change logic here
      console.log('Language changed');
    },
    changeL(lang) {
      this.changeLanguage(lang)
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang
      }
    },
    notificationn() {
      this.loadNotifications({
        pageNumber: 1,
        pageSize: 10,
      })
    },
    showOrHideSidebar() {
      if (this.getIsSidebarHidden == true) {
        this.changeVisibilityOfSidebar(false);
      } else {
        this.changeVisibilityOfSidebar(true);
      }
    },
    toggleActionDropdown() {
      // Toggle the visibility of the Action dropdown
      this.showActionDropdown = !this.showActionDropdown;
    },
  },

}
</script>

<style lang="scss" scoped>


* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Mori";
  font-weight: 300;
  height: 100vh;
  overflow: hidden;
}
.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.container.grid, .container.columns {
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;
}

.userInfo {
  display: flex;
  width: 30%;
  align-items: center;
}

.letter {
  text-align: center;
  color: white;
  font-size: 0.8vmax;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
}
.container.grid .letter {
  flex-basis: 50%;
}
.container.columns .letter {
  flex-basis: 25%;
}
.for, .gsap {
  font-size: 0.8vmax;
  color: white;
}
.for {
  padding: 2px 1.6vmax;
  font-weight: 300;
  display: none;
}
.gsap {
  padding: 2px 5px;
  font-weight: 400;
  display: none;
}
.container.final .for, .container.final .gsap {
  display: block;
}
.F {
  background: #242e6e;
}
.l {
  background:#202b91;
}
.i {
   background:#3242ab;
}
.p {
  background: #ff274f;
}
.container.plain .letter {
  background: transparent;
  color: white;
  padding: 0;
}

.logo {
  position: fixed;
  width: 100px;
  bottom: 20px;
  right: 30px;
}


nav {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  color: #fff;
}

.navbar-logo {
  font-size: 20px;
}

.notification-wrapper{
  position: relative;
  z-index: 9999 !important;
}

.navbar-profile {
  // position: relative;
  // width: 40px;
  // height: 40px;
  // border-radius: 50%;
  // background-color: white;
  cursor: pointer;
}

.navbar-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
}

.modal button {
  display: block;
  margin-bottom: 10px;
}
.leave-icon {
  display: none !important;
  cursor: pointer;
}

.albanianFlag li {
  display: flex;

  img {
    float: right;
  }
}

.burger-menu {
  display: none !important;
  color: #fff;
  cursor: pointer;

}

@media screen and (min-width: 700px) and (max-width: 1500px) {
  .userInfo {
    display: flex;
    width: 40%;
    align-items: center;
  }
}

@media only screen and (max-width: 1190px) {
  .burger-menu {
    display: inline-block !important;
    color: black;
    cursor: pointer;
  }
  .navbarParagraph {
    width: 100%;
    padding-left: 25px;
}
  .burger-menu:hover {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 655px) {
  .navbar {
    padding: 0 0.75rem !important;
  }

  .sign-out {
    font-size: 12px;
  }

  p {
    font-size: 12px;
  }

  .dropdown-language {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 655px) {
  .sign-out {
    display: none;
  }
  .action-dropdown{
    display: none;
}
.userInfo {
display:none !important;
}
  .leave-icon {
    display: inline-block !important;
  }
}
</style>
