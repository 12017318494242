/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  isSidebarHidden: true,
};

const getters = {
  getIsSidebarHidden: (state) => {
    return state.isSidebarHidden;
  },
};

const mutations = {
  UPDATE_IS_SIDEBARD_HIDDEN(state, payload) {
    state.isSidebarHidden = payload;
  },
};

const actions = {
  changeVisibilityOfSidebar({ commit }, payload) {
    // console.log('att', payload);
    commit('UPDATE_IS_SIDEBARD_HIDDEN', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
