<template>
  <div>
    <b-modal
      id="modal-personal-info"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('PersonalInformation')}:`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('FirstName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="getLoggedInUser.firstName"
            disabled
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_1') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('LastName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="getLoggedInUser.lastName"
            disabled
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_1') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Role')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="getLoggedInUser.role"
            disabled
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_1') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group />
      </b-form>
      <hr>
      <div style="display: flex; justify-content: space-between;">
        <div
          style=" color: #ffffff !important"
          class="albanianFlag"
        >
          <b-dropdown
            :text="language"
            variant="btnLanguage"
            style="margin-right:20px;"
            class="dropdown-language"
          >
            <b-dropdown-item
              variant="dropLanguage"
              @click="changeL('en')"
            >
              English
            </b-dropdown-item>
            <div>
              <b-dropdown-item
                variant="dropLanguage"
                @click="changeL('sq')"
              >
                Shqip
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>
        <div class="buttonsEverywhere">
          <!-- <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button> -->
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="background: #FF274F !important;"

            @click="logout"
          >
            {{ $t('SignOut') }}
            <font-awesome-icon
              style="margin-left:5px"
              icon="fa-solid fa-arrow-right-from-bracket"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters, mapActions } from 'vuex';
import connection from '../microsoft-signalR/signalR'
// import {
//   required, minValue,
// } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getLanguage', 'getIsSidebarHidden']),
    language() {
      if (this.getLanguage === 'en') {
        return 'English'
      }
      return 'Shqip'
    },
  },
  // validations: {
  //   form: {
  //     stock: {
  //       required,
  //       // numeric,
  //       minValue: minValue(0),
  //     },
  //   },
  // },

  methods: {
    ...mapActions(['logoutUser', 'changeLanguage', 'changeVisibilityOfSidebar', 'loadNotifications']),
    logout() {
      connection.stop()
      this.logoutUser()
      localStorage.removeItem('signupData');
      localStorage.removeItem('modalShownBefore');
      this.$router
        .push({ path: '/login' })
        // .then(() => { this.$router.go() })
      this.onCancel()
    },
    // changeLanguagee() {
    //   // Perform language change logic here
    //   console.log('Language changed');
    // },
    changeL(lang) {
      this.changeLanguage(lang)
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },

  },
}
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
  .form-group {
    margin-bottom: 5px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
</style>
