import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';


// Modules

import moduleMaterials from './modules/materials'
import client from './modules/clients'
import vacation from './modules/vacation'
import dresses from './modules/dresses'
import materialVariant from './modules/materialvariant'
import suppliers from './modules/suppliers'
import product from './modules/product'
import users from './modules/users'
import teams from './modules/teams'
import orders from './modules/orders'
import trackAndTrace from './modules/trackAndTrace'
import authentication from './modules/authentication'
import cart from './modules/cart'
import style from './modules/style'


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['authentication'],
      storage: window.localStorage,
    }),
  ],
  modules: {
    cart,
    moduleMaterials,
    client,
    vacation,
    materialVariant,
    dresses,
    suppliers,
    product,
    users,
    teams,
    orders,
    trackAndTrace,
    authentication,
    style,
  },
  strict: process.env.DEV,
});
