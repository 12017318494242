import Vue from 'vue'
import {
  BootstrapVue, ToastPlugin, ModalPlugin, IconsPlugin,
} from 'bootstrap-vue'
import axios from 'axios';

import VueGoodTablePlugin from 'vue-good-table'
import vSelect from 'vue-select'
import ToggleButton from 'vue-js-toggle-button'
// import { createI18n } from 'vue-i18n'
import Notifications from 'vue-notification';
import VueI18n from 'vue-i18n';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import VueFileAgent from 'vue-file-agent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueApexCharts from 'vue-apexcharts'
import App from './App.vue'
import router from './router'
import store from './store'
// import signalr from './microsoft-signalR/signalR'
import en from './i18n/en.json'
import sq from './i18n/sq.json'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import 'vue-file-agent/dist/vue-file-agent.css';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import connection from './microsoft-signalR/signalR';

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

const eventBus = new Vue();
export default eventBus;
library.add(faArrowRightFromBracket)

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line prefer-const
  let _token = store.state.authentication.loggedInUser.token;
  if (_token) config.headers.Authorization = `Bearer ${_token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line arrow-body-style
  (error) => Promise.reject(error),
);

// eslint-disable-next-line no-unused-vars
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: store.state.authentication.language,
  silentTranslationWarn: true,
  fallbackLocale: 'en',
  messages: {
    en,
    sq,
  },
});
Vue.use(VueFileAgent);
Vue.use(BootstrapVue)
Vue.use(ToggleButton)
Vue.use(Notifications)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue, {
  BDropdown: {
    popperOpts: {
      positionFixed: true,
    },
    boundary: 'window',
  },
  BFormInput: {
    autocomplete: 'off',
  },
  BInput: {
    autocomplete: 'off',
  },
})

// import the styles

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(IconsPlugin)
Vue.use(VueGoodTablePlugin)
Vue.component('vue-select', vSelect)
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// import assets styles
// eslint-disable-next-line import/no-unresolved
require('@/assets/scss/style.scss')

Vue.config.productionTip = false


// // Connect to SignalR in main.js
// function fulfilled() {
//   // do something on start
//   //console.log('Connection to User Hub Successful');
// }
// function rejected() {
//   console.log('Connection to User Hub Failed');
// }

// connection.start().then(fulfilled, rejected);

new Vue({
  router,
  store,
  i18n,
  connection,
  mounted() {
    AOS.init();
  },
  render: (h) => { return h(App) },
}).$mount('#app')
