<template>
  <div style="width: 100%; display: flex; justify-content: space-between; max-height: 100%;">
    <b-card
      no-body
      style="margin-top: 20px ;width: 100%;"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          active
          style=""
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("UpcomingPickUpDates") }}</strong>
          </template>
          <b-card-text>

            <div
              style=""
            >
              <div class="buttons">
                <b-button
                  v-for="(button, index) in buttons"
                  :key="index"
                  class="buttonTime"
                  type="button"
                  variant="none"
                  :class="{ 'active-button': index === activeButton }"
                  style="margin-right: 5px; margin-bottom: 5px; font-size: 12px; color: white;"
                  @click="pickUpDates(index)"
                >
                  {{ button.text }}
                </b-button>
              </div>
              <table
                responsive
                class="team_table"
                style="border-radius: 20px 10px 10px 10px; box-shadow: none;  width:100%; background-color: white;"
                @click="goToOrder"
              >
                <thead>
                  <tr style="font-size: 12px">
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("OrderN") }}
                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("ArticleN") }}
                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("Date") }}
                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("TeamName") }}
                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("Type") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="filiale in getUpComingPickUpDates"
                    :key="filiale.storeGLN"
                    style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer; font-size: 10px"
                    @click="matProps(filiale)"
                  >
                    <td style="padding: 10px">
                      {{ filiale.orderNumber }}
                    </td>
                    <td style="padding: 10px">
                      {{ filiale.articleNumber }}
                    </td>
                    <td style="padding: 10px">
                      {{ dateModified(filiale.pickUpDate) }}
                    </td>
                    <td style="padding: 10px">
                      {{ filiale.teamName !== null ? filiale.teamName : 'N/A' }}
                    </td>
                    <td style="padding: 10px">
                      {{ filiale.type }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="mainPagination"
              >
                <b-pagination
                  v-model="pageNumber"
                  :total-rows="getTotalItemsForOrders"
                  :per-page="pageSize"
                  first-number
                  last-number
                  align="fill"
                  size="md"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
                  </template>
                  <template #next-text>
                    <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
                  </template>
                </b-pagination>
              </div>



            </div></b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
              @click="top10"
            >{{ $t("TopTenLevels") }}</strong>
          </template>
          <b-card-text>
            <table
              responsive
              class="team_table"
              style="width:100%;"
              @click="goToMaterials"
            >
              <thead>
                <tr style="font-size: 12px ">
                  <th
                    style="padding: 10px;border-radius: 10px 0 0 0; font-size: 12px !important"
                  >
                    {{ $t("MaterialName") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left; font-size: 12px !important"
                  >
                    {{ $t("MaterialVariantName") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left; font-size: 12px !important"
                  >
                    {{ $t("Type") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left; font-size: 12px !important"
                  >
                    {{ $t("Total") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left; font-size: 12px !important"
                  >
                    {{ $t("MinimalQuantity") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left; font-size: 12px !important"
                  >
                    {{ $t("Difference") }}
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="filiale in paginatedData"
                  :key="filiale.materialVariantId"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer; font-size: 10px"
                  @click="matProps(filiale)"
                >
                  <td style="padding: 10px">
                    {{ filiale.materialName }}
                  </td>
                  <td style="padding: 10px; text-align: left;">
                    {{ filiale.materialVariantName }}
                  </td>
                  <td style="padding: 10px; text-align: left;">
                    {{ filiale.measurementType == 'Meter' ? 'm' : 'p' }}
                  </td>
                  <td style="padding: 10px; text-align: left;">
                    {{ filiale.total }}
                  </td>
                  <td style="padding: 10px; text-align: left;">
                    {{ filiale.minimalQuantity }}
                  </td>
                  <td
                    style="padding: 10px; text-align: left;"
                    :style="filiale.difference < 0 ? 'color:red': 'color: green'"
                  >
                    {{ filiale.difference }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              id="pagination"
              style="display: flex;
                      justify-content: end;
                      margin-top: 2px;"
            >
              <button
                class="btn "
                style="border: 1px solid lightgray;"
                @click="prevPage"
              >
                {{ $t('Previous') }}
              </button>
              <button
                class="btn "
                style="border: 1px solid lightgray;"
                @click="nextPage"
              >
                {{ $t('Next') }}
              </button>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab v-if="getLoggedInUser.role != 'TeamLeader'">
          <template #title>
            <strong
              style="font-weight: 500; color: black"
              @click="feedbackForDashboard()"
            >{{ $t("TopRatings") }}</strong>
          </template>
          <b-card-text>
            <table
              class="team_table"
              style="width: 100%; box-shadow: none; font-size: 10px"
              @click="goToFeedbacks"
            >
              <thead>
                <tr>
                  <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left">
                    {{ $t("FirstName") }}
                  </th>
                  <th style="padding: 10px; text-align: left">
                    {{ $t("LastName") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    {{ $t("Role") }}
                  </th>
                  <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: center">
                    {{ $t("Rating") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(user, index) in paginatedDataForFeedback"
                  :key="index"
                  v-b-tooltip.hover.bottom
                  style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer; font-size: 10px"
                >
                  <td style="padding: 9px">
                    {{ user.firstName }}
                  </td>
                  <td style="padding: 9px">
                    {{ user.lastName }}
                  </td>
                  <td style="padding: 9px">
                    {{ $t(user.role) }}
                  </td>

                  <td
                    style="display: flex; justify-content: center; margin-top: 3px;"
                    @click.stop
                  >
                    <b-form>
                      <b-form-rating
                        v-model="user.rangingValue"
                        v-b-tooltip.hover.top="$t('Rating') +' '+ user.rangingValue"
                        variant="warning"
                        class="mb-2"
                        readonly
                      />
                    </b-form>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              id="pagination"
              style="display: flex;
                      justify-content: end;
                      margin-top: 2px;"
            >
              <button
                class="btn "
                style="border: 1px solid lightgray;"
                @click="prevPageFeedback"
              >
                {{ $t('Previous') }}
              </button>
              <button
                class="btn "
                style="border: 1px solid lightgray;"
                @click="nextPagefeedback"
              >
                {{ $t('Next') }}
              </button>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab
          style=""
        >
          <template #title>

            <strong style="font-weight: 500; color: black">{{ $t("EmployeeBirthday") }}</strong>

          </template>
          <b-card-text>

            <div
              style=""
            >
              <div class="buttons">
                <b-button

                  v-for="(button, index) in dailys"

                  :key="index"
                  class="buttonTime"
                  type="button"
                  variant="none"

                  :class="{ 'active-button': index === activeButtoni }"
                  style="margin-right: 5px; margin-bottom: 5px; font-size: 12px; color: white;"
                  @click="birthdays(index)"
                >
                  {{ button.text }}
                </b-button>
              </div>
              <table
                responsive
                class="team_table"
                style="border-radius: 20px 10px 10px 10px; box-shadow: none;  width:100%; background-color: white;"
              >
                <thead>
                  <tr style="font-size: 12px">
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("FullName") }}
                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >

                      {{ $t("Email") }}

                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("Date") }}
                    </th>
                    <!-- <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("TeamName") }}
                    </th> -->
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >

                      {{ $t("SendEmail") }}

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr

                    v-for="(employee, index) in paginatedDataForBirthdays"
                    :key="employee.id || index"
                    style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer; font-size: 10px"
                  >
                    <!-- @click="matProps(employee)" -->
                    <td style="padding: 10px">
                      {{ employee.fullName }}
                    </td>
                    <td style="padding: 10px">
                      {{ employee.email }}
                    </td>
                    <td style="padding: 10px">
                      {{ dateModified(employee.dateOfBirth) }}

                    </td>
                    <!-- <td style="padding: 10px">
                      {{ filiale.teamName !== null ? filiale.teamName : 'N/A' }}
                    </td> -->

                    <td style="padding: 4px">

                      <b-button
                        v-b-modal.send-emmail-modal
                        class="buttonTime"
                        type="button"
                        variant="none"
                        style="margin-right: 5px; margin-bottom: 5px; font-size: 12px; color: white;"

                        @click="sendToModal(employee)"
                      >
                        {{ $t('Email') }}

                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div

                id="pagination"
                style="display: flex;
                      justify-content: end;
                      margin-top: 2px;"
              >
                <button
                  class="btn "
                  style="border: 1px solid lightgray;"
                  @click="prevPageBirthday"
                >
                  {{ $t('Previous') }}
                </button>
                <button
                  class="btn "
                  style="border: 1px solid lightgray;"
                  @click="nextPageBirthday"
                >
                  {{ $t('Next') }}
                </button>

              </div>



            </div></b-card-text>
        </b-tab>
        <b-tab
          style=""
        >
          <template #title>

            <strong style="font-weight: 500; color: black">{{ $t("ClientBirthday") }}</strong>

          </template>
          <b-card-text>

            <div
              style=""
            >
              <div class="buttons">
                <b-button

                  v-for="(button, index) in dailysClient"

                  :key="index"
                  class="buttonTime"
                  type="button"
                  variant="none"

                  :class="{ 'active-button': index === activeButtoni2 }"
                  style="margin-right: 5px; margin-bottom: 5px; font-size: 12px; color: white;"
                  @click="birthdaysClient(index)"
                >
                  {{ button.text }}
                </b-button>
              </div>
              <table
                responsive
                class="team_table"
                style="border-radius: 20px 10px 10px 10px; box-shadow: none;  width:100%; background-color: white;"
              >
                <thead>
                  <tr style="font-size: 12px">
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("FullName") }}
                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >

                      {{ $t("Email") }}

                    </th>
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("Date") }}
                    </th>
                    <!-- <th
                      style="padding: 10px; font-size: 12px !important"
                    >
                      {{ $t("TeamName") }}
                    </th> -->
                    <th
                      style="padding: 10px; font-size: 12px !important"
                    >

                      {{ $t("SendEmail") }}

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr

                    v-for="(employee, index) in paginatedDataForBirthdaysClient"
                    :key="employee.id || index"
                    style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer; font-size: 10px"
                  >
                    <!-- @click="matProps(employee)" -->
                    <td style="padding: 10px">
                      {{ employee.fullName }}
                    </td>
                    <td style="padding: 10px">
                      {{ employee.email }}
                    </td>
                    <td style="padding: 10px">
                      {{ dateModified(employee.dateOfBirth) }}

                    </td>
                    <!-- <td style="padding: 10px">
                      {{ filiale.teamName !== null ? filiale.teamName : 'N/A' }}
                    </td> -->

                    <td style="padding: 4px">

                      <b-button
                        id="birthday"
                        v-b-modal.send-emmail-modal
                        class="buttonTime"
                        type="button"
                        variant="none"
                        style="margin-right: 5px; margin-bottom: 5px; font-size: 12px; color: white;"

                        @click="sendToModal(employee)"
                      >
                        {{ $t('Email') }}

                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div

                id="pagination"
                style="display: flex;
                      justify-content: end;
                      margin-top: 2px;"
              >
                <button
                  class="btn "
                  style="border: 1px solid lightgray;"
                  @click="prevPageBirthdayClient"
                >
                  {{ $t('Previous') }}
                </button>
                <button
                  class="btn "
                  style="border: 1px solid lightgray;"
                  @click="nextPageBirthdayClient"
                >
                  {{ $t('Next') }}
                </button>

              </div>



            </div></b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>

    <SendEmailModal
      :send-to-modal-object="sendToModalObject"
      @sendEmail="sendEmail"
    />
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'
import connection from '@/microsoft-signalR/signalR';

import { client } from '@/domainConfig'

import SendEmailModal from '@/components/dashboard/modals/SendEmailForBirthdaysModal.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: {
    SendEmailModal,
  },
  data() {
    return {
      activeButton: null,
      activeButtoni: null,
      activeButtoni2: null,
      loading: false,
      orderGroup: {},
      tabIndex: 1,
      sendToModalObject: null,
      clientLogo: client.clientLogo,

      rating: 7,
      showPieChart: false,
      itemsPerPage: 4,
      buttons: [
        { text: this.$t('Daily'), days: 1 },
        { text: this.$t('Week'), days: 7 },
        { text: this.$t('Month'), days: 30 },
      ],

      dailys: [
        { text: this.$t('Daily'), days: moment().format('YYYY-MM-DD') },
        { text: this.$t('Week'), days: moment().add(7, 'days').format('YYYY-MM-DD') },
        { text: this.$t('Month'), days: moment().add(1, 'months').format('YYYY-MM-DD') },
      ],
      dailysClient: [
        { text: this.$t('Daily'), days: moment().format('YYYY-MM-DD') },
        { text: this.$t('Week'), days: moment().add(7, 'days').format('YYYY-MM-DD') },
        { text: this.$t('Month'), days: moment().add(1, 'months').format('YYYY-MM-DD') },
      ],
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        arrows: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      currentPage: 1,
      selectedOrderState: this.$t('All'),
      pickedDate: null,
      year: 2023,
      yearsArray: [],
      options1: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      visibleDiv: 'monthly',
      pageNumber: 1,
      pageSize: 4,
      stateOptions: [
        { value: null, label: this.$t('All') },
        { value: 'Created', label: this.$t('Created') },
        { value: 'Assigned', label: this.$t('Assigned') },
        { value: 'Confirmed', label: this.$t('Confirmed') },
        { value: 'InProduction', label: this.$t('InProduction') },
        { value: 'InSewing', label: this.$t('InSewing') },
        { value: 'InHandSewing', label: this.$t('InHandSewing') },
        { value: 'QualityPassed', label: this.$t('QualityPassed') },
        { value: 'ReadyToDeliver', label: this.$t('ReadyToDeliver') },
        { value: 'Delivered', label: this.$t('Delivered') },
        { value: 'StockNotAvailable', label: this.$t('StockNotAvailable') },
        { value: 'QualityCheckNotPassed', label: this.$t('QualityCheckNotPassed') },
      ],
    };
  },
  computed: {

    ...mapGetters(['getClientBirthdays', 'getEmployeeBirthdays', 'getLanguage', 'getdashboardCounterItems', 'getUsers', 'getFeedbackForDashboard', 'getClientCounter', 'getStockMaterialVariantReporting', 'getOrdersGrouppedByStateDashboard', 'getOrdersGrouppedByState', 'getLoggedInUser', 'getIsLoading', 'getUpComingPickUpDates', 'getTotalItemsForOrders', 'getMostSoldProducts']),

    colorByRate() {
      if (this.rating <= 3) {
        return 'red';
      }
      if (this.rating > 3 && this.rating <= 6) {
        return 'orange';
      }
      return 'green';
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getStockMaterialVariantReporting.slice(start, end);
    },
    paginatedDataForFeedback() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getFeedbackForDashboard.slice(start, end);
    },

    paginatedDataForBirthdays() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getEmployeeBirthdays.slice(start, end);
    },
    paginatedDataForBirthdaysClient() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getClientBirthdays.slice(start, end);
    },
  },
  watch: {
    pageNumber(value) {
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate != null ? this.pickedDate : 7,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    year(value) {
      this.dashboard_Yearly_Graph({
        year: value,
      })
    },
  },
  created() {
    this.connectToSignalR();
  },
  mounted() {
    this.fetch()
    this.most_Sold_Products()
    this.upcomingPickupDays({
      numberOfDays: 5,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    })
    this.loadClientCounter()
    this.dashboardCounterItemsAndProductInStock()

    const currentYear = new Date().getFullYear();

    // Create an array with the last year, current year, and next 5 years
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }

    // Set the selected year to the current year
    this.year = currentYear;

    // console.log('most sold', this.getMostSoldProducts)
  },
  methods: {
    ...mapActions(['clientBirthdays', 'loadOrdersGrouppedByState',
      'dashboard_Yearly_Graph', 'sendEmailToUser', 'sendEmailToClientForDress',
      'reporting_less_stockMaterialVariants', 'loadOrdersGrouppedByState', 'loadFeedbackForDashboard',
      'changeLoadingtoTrue', 'resetOrdersOverview', 'upcomingPickupDays', 'loadOrdersGrouppedByState', 'most_Sold_Products', 'loadClientCounter', 'dashboardCounterItemsAndProductInStock', 'getUsersPagination', 'employeeBirthdays', 'clientBirthdays']),
    sendToModal(value) {
      this.sendToModalObject = value
    },
    sendEmail(value) {
      console.log(value)
      if (this.tabIndex == 3) {
        this.sendEmailToUser({
          object: {
            email: this.sendToModalObject.email,
            discount: value.discount,
            fullName: this.sendToModalObject.fullName,
            ocassion: 'Birthday',
            iconUrl: this.clientLogo,
          // iconUrl: 'https://spim-file.s3.amazonaws.com/DrenushaLogoWhite.png',
          },
        })
      } else if (this.tabIndex == 4) {
        this.sendEmailToClientForDress({
          object: {
            email: this.sendToModalObject.email,
            discount: value.discount,
            fullName: this.sendToModalObject.fullName,
            ocassion: 'Birthday',
            iconUrl: this.clientLogo,
            dressUrl: null,
          // iconUrl: 'https://spim-file.s3.amazonaws.com/DrenushaLogoWhite.png',
          },
        })
      }
    },
    // birthdaysClient(index) {
    //   this.currentPage = 1
    //   this.activeButtoni2 = index;
    //   this.pickedDate = this.dailysClient[index].days;
    //   this.clientBirthdays({
    //     startDate: moment().format('YYYY-MM-DD'),
    //     endDate: this.pickedDate,
    //     clientName: this.client,
    //   });
    // },

    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },

    top10() {
      this.reporting_less_stockMaterialVariants()
    },
    goToOrder() {
      this.$router.push('orders-overview/all')
    },
    goToMaterials() {
      this.$router.push('inventory')
    },
    goToFeedbacks() {
      this.$router.push('employee-payments')
    },
    goToClients() {
      this.$router.push('clients')
    },
    goToProducts() {
      this.$router.push('products')
    },
    goToProductsInStock() {
      this.$router.push('products-in-stock')
    },
    feedbackForDashboard() {
      this.loadFeedbackForDashboard()
    },
    updateShowPieChart() {
      this.showPieChart = true; // Update showPieChart to true when PieChart is displayed
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      const totalPages = Math.ceil(this.getStockMaterialVariantReporting.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    prevPageFeedback() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPagefeedback() {
      const totalPages = Math.ceil(this.getFeedbackForDashboard.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },

    prevPageBirthday() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPageBirthday() {
      const totalPages = Math.ceil(this.getEmployeeBirthdays.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    prevPageBirthdayClient() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPageBirthdayClient() {
      const totalPages = Math.ceil(this.getClientBirthdays.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        // console.log('Connection to User Hub Successful');
      }
      function rejected() {
        //      console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        setTimeout(() => {
          connection.start().then(fulfilled, rejected);
        }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },

    disconnectFromSignalR() {
      connection.stop();
    },

    async fetch() {
      // this.changeLoadingtoTrue(false)

      try {
        await this.loadOrdersGrouppedByState()
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    handleInput() {
      // Remove any non-digit characters
      this.year = this.year.replace(/\D/g, '');

      // Limit the year to 4 digits
      this.year = this.year.slice(0, 4);
    },
    showDiv(divId) {
      this.visibleDiv = divId;
    },
    pickUpDates(index) {
      this.activeButton = index; // Set the active button
      this.pickedDate = this.buttons[index].days; // Set the pickedDate
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate, // Use the pickedDate value
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },

    birthdays(index) {
      this.currentPage = 1
      this.activeButtoni = index;
      this.pickedDate = this.dailys[index].days;
      this.employeeBirthdays({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: this.pickedDate,
      });
    },
    birthdaysClient(index) {
      this.currentPage = 1
      this.activeButtoni2 = index;
      this.pickedDate = this.dailysClient[index].days;
      this.clientBirthdays({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: this.pickedDate,
        clientName: this.client,
      });
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
  },


};
</script>

<style lang="scss" scoped>

.main{
  // padding: 70px 15px 0px 105px;
  display: flex;
  flex-direction: column;
}

.card-content h6{
  font-size: 15px;
}

.stateChart{
  width: 100%;
  display: flex;
}

.line{
  width: 50%;
}

.tableicons{
  text-align: center;
}

.tableicons img{
  width: 90px;
}

.blue{
  background-color: #242f6e;
  border-color: #242f6e;
  color: white;
 }

 .green{
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
 }
 .red{
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
 }

.tableLeads{
  display: flex;
  width: 100%;
}

.chart{
  width: 99%;
}

.nav-tabs .nav-item .nav-link {
    background-color: transparent !important; /* Remove default background */
  }

  .nav-tabs .nav-item.active .nav-link {
    background-color: red !important; /* Add your custom background color */
    color: #ffffff !important; /* Text color for the active tab */
  }

.row{
  // margin-top:100px;
}

.custom-carousel .carousel-indicators {
  display: none !important;
}
.slider{
  width: 100%;
}

.slick-slide slick-active slick-current{
  width: 100%
}

.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}
.slick-track{
  width: 100%
}

.mainPagination{
  font-size: 12px;
}


.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}

.b-tabs .nav-item .nav-link {
  background-color: rgb(80, 80, 86) !important;
  color: white;
}
.b-tabs .nav-link.active {
  background-color: rgb(99, 99, 113) !important;
}

.light-card__wrapper {
  padding: 0 0px 15px 0px;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  margin-left: 8px;
  margin-right: 8px;
  grid-row-gap: 12px;
  background-color: #242F6E;
    padding: 22px;
    border-radius: 10px;
}
.upcomingLowest{
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-evenly;
}

.barPieChart{
  display: flex;
}

.main__title{
  margin-bottom: 30px;
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
}
 .hide {
  display: none;
 }
 .blue{
  background-color: #242f6e;
  border-color: #242f6e;
  color: white;
 }
//  .container{
//   margin: 10px;
//   padding: 0px;
//  }
 .blue2{
//  border-top: 4px solid #242f6e;
  // background: linear-gradient(356deg, #15E582 0%, #000000 100%);
  // background-clip: padding-box;
  border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #128EEE 70% , #424242 );
}
.green2{
//  border-top: 4px solid #0cb893;
 border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #15E582  70% , #424242 );
}

.buttonTime{
  background: #FF274F !important;
    color: #f4f1ed;
    border-color: #262E6C;
}

.buttons{
  width: 50%
}
.red2{
//  border-top: 4px solid #FF274F;
border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #F11313   70% , #424242 );
}
 .green{
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
 }
 .red{
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
 }
 .light-card {
        overflow-wrap: anywhere;
  }

  .team_table{
    box-shadow: none;
    background-color: white;
  }

@media screen and (min-width: 1400){
  .chart{
    width: 100% !important;
  }
}

@media screen and (min-width: 1341px) and (max-width: 1650px){
  .card-content h6{
    font-size: 13px;
  }
  .tableicons img{
    width: 70px !important;
  }
}

 @media screen and (max-width: 1340px) {
  .main {
    padding: 180px 0 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table{
      width: 90%;
    }

    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination{
      font-size: 12px;
      width: 90%;
    }

    .tableicons img{
      width: 80px;
    }
  }
  .stateChart{
    width: 100%;
    display: block;
  }
  .line{
    width: 90%;
    margin-top: 30px;
    margin-left: 50px;
  }
  .tableLeads{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 1100px){
  .main{
    padding: 140px 39px 0px 40px !important;
  }
}

 @media screen and (max-width: 800px) {
  .main {
    padding: 130px 25px 0px 25px !important;
    .container {
      .light-card__wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
        margin-left: 8px;
        margin-right: 8px;
      }

      .bar-pie-chart{
        display: block;
      }

      .light-card {
        overflow-wrap: anywhere;
      }
    }
    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .main__title{
      margin-bottom: 10px;
      font-size: 30px;
      margin-top: 50px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1340px) {
  .main {
    padding: 180px 0 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table {
      width: 90%;
    }

    .upcomingLowest {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination {
      font-size: 12px;
      width: 90%;
    }
  }
}

@media screen and (max-width: 1190px){
  .main{
    padding: 130px 40px 0 40px !important;
  }
}
.active-button {
  background: transparent linear-gradient(52deg, #f7f7f7 0%, #fbfbfb 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
    color: #262E6C !important;
    border-color: transparent;
}

</style>
