<template>
  <div :style="$route.path.includes('create-order')? 'width: 33% !important; margin-left: 5px;': 'width: 100% !important; ' ">
    <b-form-group
      id="input-group-1"
      :label="$t(labelText)"
      label-for="input-1"
      width="auto"
    >
      <vue-dropzone
        id="customdropzone"
        ref="myVueDropzone"
        :options="dropzoneOptions"
        :include-styling="true"
        @vdropzone-files-added="fileAdded"
        @vdropzone-removed-file="removeFile"
      />
      <p
        v-show="showFileError == true"
        :class="{ 'hidden': showFileError == true}"
        style="color:#ff0000 ;"
      >{{ $t('ErrorUploadingPhoto') }}</p>
      <pre />
    </b-form-group>
  </div>
</template>
<script>
import AWS from 'aws-sdk';
import vue2Dropzone from 'vue2-dropzone'
import { AWSurl } from '../domainConfig';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['fileName', 'labelText', 'folder', 'maxFiles', 'fileDataType'],
  data() {
    return {
      showFileError: false,
      fileData: [],
      bucketName: AWSurl.bucketName,
      dropzoneOptions: {
        url: 'https://localhost.org/post',
        thumbnailWidth: 100,
        autoProcessQueue: false,
        thumbnailHeight: 100,
        multiple: true,
        dictDefaultMessage: `<span><i class='fa fa-cloud-upload fa-3x' ></i><p style='margin-top: 10px; font-size: 14px; font-weight: 500; color: #667085'><span style="color: #FF274F; font-weight: 600">${this.$t('ClickToUpload')}</span>${this.$t('DragDrop')}<br>PDF, PNG, JPG or .doc</p></span>`,
        addRemoveLinks: true,
        error: false,
        maxFilesize: 50,
        headers: { 'My-Awesome-Header': 'header value' },
      },
    }
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  beforeDestroy() {
    this.showFileError = false;
  },
  methods: {
    onBeforeDelete(fileRecord) {
      // optionally, you can confirm and call the deleteFileRecord method
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    fileAdded(event) {
      const files = event;
      console.log('Files added:', event);

      const self = this; // Capture 'this' to use inside the FileReader callback

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Create a placeholder object, even if file.upload is not immediately available
        const fileInfo = {
          fileName: file.name.split('.')[0],
          filetype: file.type.split('/')[1],
          index: file.upload && file.upload.uuid ? file.upload.uuid : null, // Temporarily set to null if upload.uuid is not available
        };

        const fileContent = new FileReader();

        fileContent.onload = function (events) {
          fileInfo.fileContent = events.target.result;

          // Push file info to the array, even without UUID, to display progress
          self.fileData.push(fileInfo);

          // Once all files are processed, trigger additional actions
          if (self.fileData.length === files.length) {
            // Process completed files here if needed
          }
        };

        // Read the file content (this happens immediately)
        fileContent.readAsDataURL(file);

        // Check later when `upload` becomes available (for drag-and-drop, it may populate later)
        if (!file.upload || !file.upload.uuid) {
          // Add a watcher or hook to wait for the upload UUID (based on how your file-uploading library works)
          // Polling approach or a reactive update could be used if the library updates upload info later
          this.$nextTick(() => {
            if (file.upload && file.upload.uuid) {
              // Update the file record with the correct UUID
              fileInfo.index = file.upload.uuid;
            }
          });
        }
      }
    },
    removeFile(uuid) {
      this.fileData.splice(uuid, 1);
    },
    uploadFileToS3(fileData) {
      console.log('fileof', fileData.fileContent)
      const buf = Buffer.from(fileData.fileContent.replace(/^data:(image\/\w+|application\/pdf|text\/plain|application\/msword|application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document|application\/vnd\.ms-excel|application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet|image\/svg\+xml);base64,/, ''), 'base64');
      return new Promise((resolve, reject) => {
        const isUsersRoute = this.$route.path.includes('users') || this.$route.path.includes('/finance-report');
        const params = {
          Bucket: AWSurl.bucketName,
          Key: `${this.folder}/${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: buf,
          ContentType: `application/${fileData.filetype}`, // Set the appropriate content type
          ACL: isUsersRoute ? null : 'public-read',
        };

        this.s3.putObject(params, (error) => {
          if (error) {
            console.log('File upload failed to S3:', error);
            reject(error);
          } else {
            if (this.$route.path.includes('create-order')) {
              this.fileName.push({ fileName: params.Key, fileDataType: this.fileDataType });
            } else if (this.$route.path.includes('sell-products')) {
              this.fileName.push({ fileName: params.Key, fileDataType: this.fileDataType });
            } else if (this.$route.path.includes('users')) {
              this.fileName.push({ key: [params.Key], documentType: this.fileDataType, expiryDate: '2023-12-12' });
            } else if (this.$route.path.includes('/admin') || this.$route.path.includes('/orders-management')) {
              this.fileName.push({ fileName: params.Key, fileDataType: this.fileDataType });
            } else if (this.$route.path.includes('/order-item-location')) {
              this.fileName.push({ fileName: params.Key, fileDataType: this.fileDataType });
            } else if (this.$route.path.includes('/finance-report')) {
              this.fileName.push(params.Key)
            } else {
              this.fileName.push(params.Key)
            }
            console.log('File uploaded successfully to S3');
            resolve();
          }
        });
      });
    },
    async uploadFile() {
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData of this.fileData) {
        // eslint-disable-next-line no-await-in-loop
        await this.uploadFileToS3(fileData);
      }
    },
  },
}
</script>
<style>
 @import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
  #customdropzone .dz-success-mark, .dz-error-mark, .dz-remove {
    margin-left: 0px !important;
    font-size: 0.5rem !important ;
    width: 100% !important;
    bottom: 1px !important;
  }
  /* .dz-error-mark{
    display: none !important;
  } */
  .dz-progress{
    display: none !important;
  }
  .dz-size{
    display: none !important;
  }
  .dz-filename{
    width: 100%;
  }
</style>
