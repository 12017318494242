<template>
  <div
    class="notification-dropdown"
  >
    <button
      class="notification-button"
      @click="toggleDropdown(); "
    >
      <b-icon
        style="height: 40px; width: 22px; color:#3A3A3A;"
        icon="bell"
        aria-hidden="true"
        class="white-icon"
      />
      <!-- || getNotificationCountFromBack > 0 -->
      <span
        v-if="getNotificationCountFromBack > 0"
        class="notification-counter"
      >
        {{ getNotificationCountFromBack }}
      </span>
    </button>

    <div
      v-show="showDropdown"
      ref="dropdown"
      v-click-outside="handleClickOutside"
      class="dropdown-content"
    >
      <div style="display: flex; justify-content: space-between;">
        <h1 class="notification-title">
          {{ $t('Notifications') }}
        </h1>
        <p
          style="text-decoration: none; cursor: pointer; padding-right: 10px;padding-top: 15px;color:red"
          @click="markAll"
        >
          {{ $t('MarkAsRead') }}
        </p>
      </div>
      <div
        v-if="getNotifications.length === 0"
        class="no-notifications"
        style="padding: 10px;"
      >
        {{ $t('NoNotifications') }}
      </div>
      <ul
        v-else
        class="notification-list"
      >
        <li
          v-for="item in getNotifications"
          :key="item.notificationId"
          class="notification-item "
          :style="item.isOpened ? '': 'background-color: rgba(255, 39, 79, 0.1); padding: 6px; border-radius: 0px; margin-bottom: 0px'"
        >
          <!-- <div class="notification-icon">
            <b-icon icon="info-circle" />
          </div> -->
          <div
            v-show="showDropdown"
            ref="dropdown"
            class="notification-message router-link-wrapper"
          >
            <router-link
              v-show="showDropdown"
              ref="dropdown"
              :to="{ path: '/orders-overview/Assigned' }"
              style="  text-decoration: none;
              color: inherit;"
              @click="decreaseNotificationCount();
                      showDropdown = false;
              "
            >
              <!-- :style="item.isOpened ? '': 'color: red'" -->
              <p
                class="notification-text"
                style="  text-decoration: none;
                color: inherit;"
                @click="markOne(); notificationIDdd(item.notificationId)"
              >
                {{ item.message }}
              </p>
            </router-link>
            <p class="notification-time">
              {{ dateModified(item.created) }}
            </p>
          </div>
        </li>
      </ul>
      <div
        v-if="getNotifications != 0"
        class="pagination-container"
      >
        <b-pagination
          v-model="pageNumber"
          :total-rows="getTotalItemsForUsers"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemiii"
          next-class="next-itemiii"
          class="mt-1 mb-0"
          limit="3"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import connection from '../microsoft-signalR/signalR'


export default {
  data() {
    return {
      showDropdown: false,
      // notificationCount: 2,
      pageNumber: 1,
      pageSize: 15,
      dropdownOpen: false,
      notiID: null,
    };
  },
  computed: {
    ...mapGetters(['getNotifications', 'getNotificationCount', 'getTotalItemsForUsers', 'getNotificationCountFromBack', 'notificationCount']),
    getTotalNotificationCount() {
      if (this.getNotificationCount === 0) {
        return this.getNotificationCountFromBack;
      } if (this.getNotificationCountFromBack === 0) {
        return this.getNotificationCount;
      }
      return this.getNotificationCount;
    },
    updatedNotificationCount() {
      return this.notificationCount != null ? this.notificationCount : this.getNotificationCountFromBack;
    },
  },
  watch: {
    getTotalNotificationCount() {
      if (this.getNotificationCount === 0) {
        return this.getNotificationCountFromBack;
      } if (this.getNotificationCountFromBack === 0) {
        return this.getNotificationCount;
      }
      return this.getNotificationCount + this.getNotificationCountFromBack;
    },
    pageNumber(value) {
      this.loadNotifications({
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    getNotificationCountFromBack() {
      this.notifications_number()
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.notifications_number()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line func-names
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  methods: {
    ...mapActions(['loadNotifications', 'resetNotificationCount', 'mark_all_read', 'mark_read', 'notifications_number', 'resetNotificationCountFromBack']),
    toggleDropdown() {
      // this.resetNotificationCount();
      // this.resetNotificationCountFromBack();
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        this.loadNotifications({
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      }
    },
    notificationIDdd(notificationId) {
      connection.invoke('MarkReadNotificationAlert', notificationId)

        .then((value) => {
          console.log(value);
        })

        .catch((error) => {
          console.error(error);
        });
    },
    markAll() {
      this.mark_all_read();
      this.loadNotifications({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
      // eslint-disable-next-line no-unused-expressions
      this.getTotalNotificationCount == 0
    },
    decreaseNotificationCount() {
      this.showDropdown = false;
      if (this.getNotificationCount > 0) {
        this.getNotificationCount--;
      } else if (this.getNotificationCountFromBack > 0) {
        this.getNotificationCountFromBack--;
      }
    },
    markOne() {
      this.loadNotifications({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    dateModified(value) {
      return moment(value).format('DD-MM-YYYY HH:mm');
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
  },
};
</script>


<style scoped>
.dropdown {
  position: absolute;
  top: 100%;
  right: 21%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  /* Add any other desired styles */
}

.pagination-container .page-item:not(:first-child):not(:last-child) {
    display: none;
  }

.notification-dropdown {
  /* position: relative;
  margin-left: 60px; */
  z-index: 9999 !important;
}



.notification-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 117%;
  right: 0;
  width: 420px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #DCDFE4;
  border-radius: 4px;
  padding: 0px;
  overflow-x:hidden;
}

.notification-title {
  font-size: 18px;
  margin-bottom: 16px;
  padding-left: 10px;
  padding-top: 15px;
}

.notification-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border-radius: 10px;
  padding:0px;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 8px 8px;
  border-bottom: 1px solid #eee;
}

.notification-icon {
  margin-right: 8px;
  color: #777;
}

.notification-text {
  margin: 0;
}

.notification-time {
  margin: 0;
  color: #999;
  font-size: 12px;
}

.see-all-link {
  display: block;
  text-align: left;
  color: #007bff;
  margin-top: 16px;
}

.notification-counter {
  position: absolute;
  top: 0;
  right: 4.2%;
  background-color: #ff5252;
  color: #fff;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.router-link-wrapper {
  /* Add your custom styles here */
  /* For example, to remove underline and change color: */
  text-decoration: none;
  color: inherit;
}
</style>
