<template>
  <section
    v-if="getIsSidebarHidden == false"
    class="sidebar"
    style="margin-top: 0rem; box-shadow: none; padding-bottom: 0%; padding-top: 0%;"
    :class="{ hide: getIsSidebarHidden == true,
              show: getIsSidebarHidden == false,
              'hovered': isHovered }"
    @mouseover="setHovered(true)"
    @mouseout="setHovered(false)"
  >
    <div class="allButtons">
      <div>
        <b-list-group>
          <b-list-group-item
            button
            style="border-bottom: 1px solid #DDE0E5 !important; height: 60px;"
          >
            <router-link
              to="/"
              style="background-color: white !important;border: none !important;"
            >
              <img
                src="@/assets/images/M-logo.svg"
                width="40px"
                alt="test"
                class="image1"
              >
              <img
                src="@/assets/images/SpimFashionLogo.svg"
                width="100px"
                alt="test"
                class="image2"
              >
            </router-link>
          </b-list-group-item>

          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'TeamLeader' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant' && getLoggedInUser.role !== 'Sewer'
                && getLoggedInUser.role !== 'PatternMaker' &&
                getLoggedInUser.role !== 'OnlineSales' &&
                getLoggedInUser.role !== 'Designer' &&
                getLoggedInUser.role !== 'SalesPerson' &&
                getLoggedInUser.role !== 'Accountant'&&
                getLoggedInUser.role !== 'StockManager' &&
                client != 'DrenushaXharra' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer' &&
                getLoggedInUser.role !== 'StockManagerMaterialVariant'
            "
            button
          >
            <router-link
              to="/products-in-stock"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("MerchInStore") }}
              </p>
            </router-link>
          </b-list-group-item>

          <b-list-group-item
            v-if="
              getLoggedInUser.role !== 'Receptionist' && getLoggedInUser.role !== 'Deliverer' && getLoggedInUser.role !== 'Receptionist' && getLoggedInUser.role !== 'StockManager' &&
                getLoggedInUser.role !== 'Accountant' &&
                getLoggedInUser.role !== 'Security' &&
                getLoggedInUser.role !== 'Chauffer' &&
                getLoggedInUser.role !== 'CleaningPerson' &&
                getLoggedInUser.role !== 'HR' &&
                getLoggedInUser.role !== 'StockManagerWarehousProductions' &&
                getLoggedInUser.role !== 'HRFinance' &&
                getLoggedInUser.role !== 'Deliverer'"
            button
          >
            <router-link
              to="/inventory-overview"
              @click.native="clickLink"
            >
              <p class="card-text">
                {{ $t("InventoryOverview") }}
              </p>
            </router-link>
          </b-list-group-item>
        </b-list-group></div>
      <b-list-group class="settings">
        <b-list-group-item
          v-b-modal.modal-personal-info
        >
          <router-link
            to="/nowhere"
            class="disabled-link"
          >
            <img
              src="@/assets/images/settings.svg"
              width="30px"
              alt="test"
            >
            <p
              style="margin-left: 10px; font-size: 16px; font-weight: 400;"
            >{{ $t("Settings") }}</p>
          </router-link>
        </b-list-group-item>
      </b-list-group>
    </div>
    <Personal />

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig'
import Personal from '@/components/personalInfo.vue'


export default {
  components: {
    Personal,
  },

  data() {
    return {
      isHovered: false,
      width: 0,
      client: client.clientName,
    };
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getIsSidebarHidden']),
  },
  watch: {
    width(value) {
      // console.log(value);
      if (value <= 1190) {
        this.changeVisibilityOfSidebar(true);
      } else {
        this.changeVisibilityOfSidebar(false);
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions([
      'resetDressColors',
      'resetSizes',
      'resetProductsByArticleNumber',
      'resetCreatedOrdersByOrderNumber',
      'changeVisibilityOfSidebar',
    ]),
    setHovered(value) {
      this.isHovered = value;
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    clickLink() {
      if (this.width <= 1190) {
        this.changeVisibilityOfSidebar(true);
      }
    },
    reset() {
      this.resetDressColors();
      this.resetSizes();
      this.resetProductsByArticleNumber();
    },
  },
};
</script>

<style scoped>

.sidebar .list-group-item a.active {
  background-color: rgba(255, 39, 79, 0.1) !important;
  border-left: 3px solid #ff274f !important;
}
.sidebar:hover .image1 {
  display: none !important;
}
.sidebar:hover .image2 {
  display: block !important;
}
.sidebar .image2{
  display:none !important;
}
.sidebar .list-group .collapse .list-group-item a.router-link-active {
    color: #242F6E;
    background-color:rgba(255, 39, 79, 0.1) !important;
    display: none;
  border-left: 3px solid #ff274f !important;

}
.sidebar .list-group .list-group-item a.router-link-exact-active {
    color: #242F6E;
    background-color: rgba(255, 39, 79, 0.1) !important;
    border-left: 3px solid #ff274f !important;
}
.sidebar:hover .collapse .list-group-item a.router-link-active{
    display: flex;
    color: #242F6E;
    background-color: #e8e8e8;
  border-left: 3px solid #ff274f !important;

}
.sidebar .list-group-item .collapse-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translatey(-50%);
    transition: ease all 0.4s;
    display: none;
}
.sidebar:hover .collapse-icon{
  display: block;
}
.sidebar .list-group .list-group-item p {
  display: none;
}

.sidebar .list-group-item a.active {
  background-color: #e8e8e8;
}

.allButtons{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%
}

.settings{
  border-top: 1px solid #DDE0E5;
  border-radius: 0px;
}

.sidebar {
  display: block !important;
  overflow: hidden;
  width: 5% !important;
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.sidebar:hover .list-group-item p {
  display: block;
  width: 100%;
}

.sidebar:hover {
  width: 17% !important;
  overflow: auto !important;
  height: 100vh;
  transition: width 0.16s; /* Disable transition when hovered */
}
.hide {
  display: none !important;
}
button a p{
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
}
.show {
  display: block !important;
}

.side-enter-active,
.side-leave-active {
  transition: transform 0.2s ease;
}

.side-enter,
.side-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

/* .router-link-active,
  .router-link-exact-active {
    background-color: red;
    color: #fff !important;
  } */

@media screen and (min-width: 1500px) and (max-width: 1700px) {
  .sidebar .list-group .list-group-item a {
    padding: 0.9rem 1.5rem;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .sidebar .list-group .list-group-item a {
    padding: 0.9rem 1.2rem;
  }
}

@media screen and (max-width: 1300px){
  .sidebar {
    display: none;
    width: 6% !important;
  }
  .sidebar .list-group .list-group-item a {
    padding: 0.9rem 1.2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    display: none;
    width: 7% !important;
  }
}

@media only screen and (max-width: 1190px) {
  .sidebar .list-group .collapse .list-group-item a.router-link-active {
    color: #242F6E;
    background-color: #e8e8e8;
    display: block;
}
.sidebar:hover .collapse .list-group-item a.router-link-active{
/* display: none; */
color: #242F6E;
    background-color: #e8e8e8;
}
.sidebar .list-group-item .collapse-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translatey(-50%);
    transition: ease all 0.3s;
    display: block;
}
.sidebar:hover .collapse-icon{
  display: none;
}
.sidebar .list-group .list-group-item p {
  display: block;

}

.sidebar {
  display: block !important;
  overflow: auto;
  width: 60% !important;
  transition: width 0.3s ease;
}

.sidebar:hover .list-group-item p {
  display: block;
  width: 100%;
}

.sidebar:hover {
  width: 25% !important;
  height: 100vh;
  display: none;
}
  .sidebar {
    display: block !important;
  }
}

/* @media screen and (max-width: 767px) {
  .sidebar {
    display: none;
  }
} */
</style>
