import axios from 'axios'
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.userManagementDomain;


const state = {
  loggedInUser: {},
  cachedOrderData: null,
  isLoggedIn: false,
  isLoading: true,
  language: 'en',
};

const getters = {
  getLoggedInUser: (state) => {
    return state.loggedInUser;
  },
  getIsLoggedIn: (state) => {
    return state.isLoggedIn;
  },
  getIsLoading: (state) => {
    return state.isLoading
  },
  getLanguage: (state) => {
    return state.language
  },
}

const mutations = {
  cacheOrderData(state, data) {
    state.cachedOrderData = data;
  },
  UPDATE_LOGGEDIN_USER(state, payload) {
    state.loggedInUser = payload;
  },
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LANGUAGE(state, payload) {
    state.language = payload
  },
};

const actions = {
  async attemptLogin({ commit }, { object, successCallback }) {
    const response = await axios.post(`${domain}/login`, object);
    commit('UPDATE_LOGGEDIN_USER', response.data);
    commit('SET_IS_LOGGED_IN', true)
    successCallback();
  },
  async logoutUser({ commit }) {
    commit('UPDATE_LOGGEDIN_USER', {});
    commit('SET_IS_LOGGED_IN', false)
    window.sessionStorage.clear();
  },
  async changePasswordForUser({ commit }, { object, successCallback }) {
    const response = await axios.put(`${domain}/changePasswordFirstLogin`, object)
    commit('UPDATE_LOGGEDIN_USER', response.data)
    commit('SET_IS_LOGGED_IN', true)
    successCallback();
  },
  test({ commit }) {
    commit('SET_IS_LOGGED_IN', true);
  },
  changeLoadingtoTrue({ commit }, value) {
    commit('SET_IS_LOADING', value)
  },
  changeLanguage({ commit }, lang) {
    commit('SET_LANGUAGE', lang);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
