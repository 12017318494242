<template>
  <div >
    <!-- <Navbar />
    <Sidebar /> -->
    <!-- <Main v-if="client === 'Development'" /> -->
    <LowStateTypeDashboard v-if="stateType === 'Low'" />
    <MediumStateTypeDashboard v-if="stateType === 'Medium'" />
    <HighStateTypeDashboard v-if="stateType === 'High'" />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
// import Main from '@/components/Main.vue'
import LowStateTypeDashboard from '@/components/dashboard/lowStateType/LowStateTypeDashboard.vue'
import HighStateTypeDashboard from '@/components/dashboard/highStateType/HighStateTypeDashboard.vue'
import MediumStateTypeDashboard from '@/components/dashboard/mediumStateType/MediumStateTypeDashboard.vue'
import { client } from '../domainConfig'

export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    LowStateTypeDashboard,
    HighStateTypeDashboard,
    // Main,
    MediumStateTypeDashboard,
  },
  data() {
    return {
      client: client.clientName,
      stateType: client.stateType,
    }
  },
}
</script>
