<!-- eslint-disable no-undef -->
<template>
  <div id="app">
    <Navbar
      v-if="!$route.path.includes('/lockout')"
    />
    <div v-if="screenWidth < 500">
      <SideBarMobile
        v-if="getLoggedInUser.token"
      />
    </div>
    <div v-else>
      <div v-if="client == 'ValdrinSahiti'">
        <SideBarVS v-if="getLoggedInUser.token" />
      </div>
      <div v-else-if="client == 'WaloneFashion'">
        <SideBarWalone
          v-if="getLoggedInUser.token"
        />
      </div>
      <div v-else-if="client == 'NotaBrillant'">
        <SidebarNota v-if="getLoggedInUser.token" />
      </div>
      <div v-else>
        <Sidebar
          v-if="getLoggedInUser.token"
        />
      </div>
    </div>
    <!-- <div v-if="client == 'WaloneFashion'">
      <SideBarWalone v-if="getLoggedInUser.token" />
    </div> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <router-link :to="notificationLink">
      <notifications
        group="foo"
        position="bottom right"
        classes="my-style"
      />
    </router-link>
    <div
      v-if="!getIsLoading"
      style="position:absolute; top: 50%; left: 50%"
    >
      <Spinner style="padding-top: 10% !important ; padding-left: 40%" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Sidebar from '@/components/sidebars/Sidebar.vue';
import Navbar from '@/components/Navbar.vue'
import SideBarVS from '@/components/sidebars/SideBarVS.vue'
import SideBarWalone from '@/components/sidebars/SideBarWalone.vue'
import SideBarMobile from '@/components/sidebars/SideBarMobile.vue'
import SidebarNota from '@/components/sidebars/SidebarNota.vue'
import { client } from '@/domainConfig'
import connection from './microsoft-signalR/signalR';
import Spinner from './components/Spinner/Spinner.vue';


export default {
  name: 'Home',
  components: {
    Sidebar,
    Navbar,
    Spinner,
    SideBarVS,
    SideBarWalone,
    SideBarMobile,
    SidebarNota,
  },
  data() {
    return {
      client: client.clientName,
      notificationLink: '',
      notificationCount: 0,
      logoutTimer: null,
      test: process.env.VUE_APP_REGION,
      showModal: false,
      birthdayNotification: '',
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getIsLoading', 'getUsers']),
  },
  created() {
    // console.log('env', process.env.VUE_APP_REGION)
    if (!this.getLoggedInUser.token) {
      this.$router.push('/login');
    } else {
      // Start the logout timer and add event listeners
      // this.startLogoutTimer();
      this.addEventListeners();
      this.$nextTick(() => {
        this.connectToSignalR();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this.removeEventListeners();
    this.disconnectFromSignalR();
  },
  mounted() {
    // this.changeLoadingtoTrue(true)

    // connect to methods that hub invokes aka receive notfications from hu
    window.addEventListener('resize', this.handleResize);



    // if (this.getLoggedInUser == 'TeamLeader') {
    function playNotificationSound() {
      const audio = new Audio('./assets/sounds/click-124467.mp3');
      audio.play();
    }

    // console.log('AppBefore', connection)
    // if (this.getLoggedInUser.role == 'TeamLeader') {
    connection.on('sendAssignmentNotificationAlert', (arg, arg2) => {
      // this.incrementNotificationCount();

      playNotificationSound();

      this.$notify({
        group: 'foo',
        title: this.$t('Notification'),
        text: `${this.$t('Order')}: ${arg} ${this.$t('with_article_name')}: ${arg2} ${this.$t(
          'has_been_assigned_to_you',
        )}.`,
        duration: 5000,
        speed: 700,
        width: 400,
        reverse: false,
      });

      this.notificationLink = '/orders-overview/Assigned';

      playNotificationSound();
    });
    // console.log('AppAfter', connection)

    // }
    // if (this.getLoggedInUser.role == 'TeamLeader') {
    connection.on('sendNotificationCounter', (arg) => {
      this.incrementNotificationCount(arg);

      // this.$notify({
      //   group: 'foo',
      //   title: this.$t('Notification'),
      //   text: `${arg}`,
      //   duration: 5000,
      //   speed: 700,
      //   width: 400,
      //   reverse: false,
      // });
      // this.notificationLink = '/orders-overview/Assigned';
    });
    // }
    function fulfilled() {
    // do something on start
      // console.log('Connection to User Hub Successful');
    }
    function rejected() {
      //      console.log('Connection to User Hub Failed');
    }
    connection.start().then(fulfilled, rejected);

    // const notificationCount = 0;

    // if (this.getLoggedInUser == 'Manager' || this.getLoggedInUser == 'TeamLeader') {
    connection.on('sendCreateNewOrderNotificationAlert', (arg) => {
      this.$notify({
        group: 'foo',
        title: this.$t('Notification'),
        text: `A new order has been created.Order Number: ${arg}`,
        duration: 5000,
        speed: 700,
        width: 400,
        reverse: false,
      });
      this.notificationLink = '/orders-overview/Created';
    });
    // }

    function fulfilled1() {
      // do something on start
      // console.log('Connection to User Hub Successful');
    }
    function rejected1() {
      //      console.log('Connection to User Hub Failed');
    }

    connection.start().then(fulfilled1, rejected1);


    // if (this.getLoggedInUser == 'Manager' || this.getLoggedInUser == 'CEO' || this.getLoggedInUser == 'Admin') {
    connection.on('sendStockAvailableNotificationAlert', (arg, arg2) => {
      this.$notify({
        group: 'foo',
        title: this.$t('Notification'),
        text: `For ${this.$t('Order')}: ${arg} ${this.$t('with_article_name')}: ${arg2} ${this.$t(
          'Stock_has_been_added_and_state_has_changed_to_created',
        )}.`,
        duration: 5000,
        speed: 700,
        width: 400,
        reverse: false,
      });
      this.notificationLink = '/orders-overview/Created';
    });
    // }

    function fulfilled2() {
      // do something on start
      // console.log('Connection to User Hub Successful');
    }
    function rejected2() {
      //      console.log('Connection to User Hub Failed');
    }

    connection.start().then(fulfilled2, rejected2);
  },
  methods: {
    ...mapActions(['logoutUser', 'changeLanguage', 'changeVisibilityOfSidebar', 'incrementNotificationCount', 'changeLoadingtoTrue', 'getUsersPagination']),
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        // console.log('Connection to User Hub Successful');
        setTimeout(() => {
          connection.start().then(fulfilled, this.rejected);
        }, 5000);
      }
      function rejected() {
        //      console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        // setTimeout(() => {
        //   connection.start().then(fulfilled, rejected);
        // }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },

    disconnectFromSignalR() {
      connection.stop();
    },

    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    startLogoutTimer() {
      this.logoutTimer = setTimeout(() => {
        this.logout();
        this.$router.push('/login');
      }, 15 * 60 * 1000);
    },
    resetLogoutTimer() {
      // Reset the timer
      clearTimeout(this.logoutTimer);
      this.startLogoutTimer();
    },
    logout() {
      // Clear local storage
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('isLoading');
      localStorage.removeItem('language');
      localStorage.removeItem('isOnVacation');

      // Call logoutUser action and redirect to the login page after it resolves
      this.logoutUser().then(() => {
        this.$router
          .push({ path: '/login' })
          .then(() => { this.$router.go() })
      });
    },

    // Add event listeners for mousemove and keydown events to reset the timer
    addEventListeners() {
      document.addEventListener('mousemove', this.resetLogoutTimer);
      document.addEventListener('keydown', this.resetLogoutTimer);
    },
    // Remove event listeners when component is destroyed
    removeEventListeners() {
      document.removeEventListener('mousemove', this.resetLogoutTimer);
      document.removeEventListener('keydown', this.resetLogoutTimer);
    },
  },
};
</script>

<style scoped lang="scss">

@font-face {
  font-family: "DM Sans";
  src: local("DMSans"),
    url(../src/assets/fonts/DMSans-Regular.ttf) format("truetype");
}
</style>
