<template>
  <div
    class="stateChart"
  >
    <div
      class="light-card__wrapper"
    >
      <div
        v-for="(item, key) in getOrdersGrouppedByState"
        :key="key"
        v-b-tooltip.hover
        class="light-card"
        :title="getLanguage === 'en' ? `${$t('ClickFor')} ${$t(item.orderStateDisplayName)} ${$t('Details')}` : `${$t('ClickForDetails')} ${$t(item.orderStateDisplayName)}`"
        @click="resetOrdersOverview(),sentYear"
      >

        <div
          :disabled="getLoggedInUser.role == 'Sewer' && item.orderItemState == 'Created'"
          :event="getLoggedInUser.role == 'Sewer' && item.orderItemState == 'Created' ? '' : 'click'"
          style="
              text-decoration: none;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
          @click="sentYear(item.orderItemState)"
        >
          <img
            :src="require(`@/assets/images/ValdrinSahiti/Dashboard/LogoDashboard-${getLoggedInUser.role == 'TeamLeader'? key+=1 : getLoggedInUser.role == 'Deliverer'? key+=7 : key+1}.svg`)"
            alt=""
          >
          <div class="card-content">
            <h6>
              {{ $t(`${item.orderStateDisplayName}`) }}
            </h6>
            <h4
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <ICountUp
                :end-val="item.count"
                :options="options1"
              />
            </h4>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'
import ICountUp from 'vue-countup-v2';
import connection from '../../../../microsoft-signalR/signalR';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';


export default {
  components: {
    ICountUp,
  },
  props: ['yearCounter'],
  data() {
    return {
      yearCurrent: moment().year(),
      loading: false,
      orderGroup: {},
      rating: 7,
      showPieChart: false,
      itemsPerPage: 4,
      buttons: [
        { text: this.$t('Daily'), days: 1 },
        { text: this.$t('Week'), days: 7 },
        { text: this.$t('Month'), days: 30 },
      ],
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        arrows: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      currentPage: 1,
      selectedOrderState: this.$t('All'),
      pickedDate: null,
      year: 2024,
      yearsArray: [],
      options1: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      visibleDiv: 'monthly',
      pageNumber: 1,
      pageSize: 4,
      stateOptions: [
        { value: null, label: this.$t('All') },
        { value: 'Created', label: this.$t('Created') },
        { value: 'Assigned', label: this.$t('Assigned') },
        { value: 'Confirmed', label: this.$t('Confirmed') },
        { value: 'InProduction', label: this.$t('InProduction') },
        { value: 'InSewing', label: this.$t('InSewing') },
        { value: 'InHandSewing', label: this.$t('InHandSewing') },
        { value: 'QualityPassed', label: this.$t('QualityPassed') },
        { value: 'ReadyToDeliver', label: this.$t('ReadyToDeliver') },
        { value: 'Delivered', label: this.$t('Delivered') },
        { value: 'StockNotAvailable', label: this.$t('StockNotAvailable') },
        { value: 'QualityCheckNotPassed', label: this.$t('QualityCheckNotPassed') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getLanguage', 'getdashboardCounterItems', 'getUsers', 'getFeedbackForDashboard', 'getClientCounter', 'getStockMaterialVariantReporting', 'getOrdersGrouppedByStateDashboard', 'getOrdersGrouppedByState', 'getLoggedInUser', 'getIsLoading', 'getUpComingPickUpDates', 'getTotalItemsForOrders', 'getMostSoldProducts']),
    colorByRate() {
      if (this.rating <= 3) {
        return 'red';
      }
      if (this.rating > 3 && this.rating <= 6) {
        return 'orange';
      }
      return 'green';
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getStockMaterialVariantReporting.slice(start, end);
    },
    paginatedDataForFeedback() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getFeedbackForDashboard.slice(start, end);
    },
  },
  watch: {
    pageNumber(value) {
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate != null ? this.pickedDate : 7,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
  },
  created() {
    this.connectToSignalR();
  },
  mounted() {
  },
  methods: {
    ...mapActions(['loadOrdersGrouppedByState',
      'dashboard_Yearly_Graph',
      'reporting_less_stockMaterialVariants', 'loadOrdersGrouppedByState', 'loadFeedbackForDashboard',
      'changeLoadingtoTrue', 'resetOrdersOverview', 'upcomingPickupDays', 'loadOrdersGrouppedByState', 'most_Sold_Products', 'loadClientCounter', 'dashboardCounterItemsAndProductInStock', 'getUsersPagination']),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    sentYear(value) {
      this.$emit('yearChanged', { year: this.yearCounter, orderState: value });
      this.$router.push({
        path: `orders-overview/${value}`,
        query: { year: this.yearCounter }, // Pass the selected year
      });
    },
    top10() {
      this.reporting_less_stockMaterialVariants()
    },
    goToOrder() {
      this.$router.push('orders-overview/all')
    },
    goToMaterials() {
      this.$router.push('inventory')
    },
    goToFeedbacks() {
      this.$router.push('employee-payments')
    },
    goToClients() {
      this.$router.push('clients')
    },
    goToProducts() {
      this.$router.push('products')
    },
    goToProductsInStock() {
      this.$router.push('products-in-stock')
    },
    feedbackForDashboard() {
      this.loadFeedbackForDashboard()
    },
    updateShowPieChart() {
      this.showPieChart = true; // Update showPieChart to true when PieChart is displayed
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      const totalPages = Math.ceil(this.getStockMaterialVariantReporting.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    prevPageFeedback() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPagefeedback() {
      const totalPages = Math.ceil(this.getFeedbackForDashboard.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        // console.log('Connection to User Hub Successful');
      }
      function rejected() {
        //      console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        setTimeout(() => {
          connection.start().then(fulfilled, rejected);
        }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },

    disconnectFromSignalR() {
      connection.stop();
    },

    async fetch() {
      // this.changeLoadingtoTrue(false)

      try {
        await this.loadOrdersGrouppedByState()
          .then(() => {
            // console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    handleInput() {
      // Remove any non-digit characters
      this.year = this.year.replace(/\D/g, '');

      // Limit the year to 4 digits
      this.year = this.year.slice(0, 4);
    },
    showDiv(divId) {
      this.visibleDiv = divId;
    },
    pickUpDates(index) {
      this.activeButton = index; // Set the active button
      this.pickedDate = this.buttons[index].days; // Set the pickedDate
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate, // Use the pickedDate value
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
  },


};
</script>

<style lang="scss" scoped>

.main{
  // padding: 70px 15px 0px 105px;
  display: flex;
  flex-direction: column;
}

.card-content h6{
  font-size: 15px;
}

.stateChart{
  width: 100%;
  display: flex;
}

.line{
  width: 50%;
}

.tableicons{
  text-align: center;
}

.tableicons img{
  width: 90px;
}

.tableLeads{
  display: flex;
  width: 100%;
}

.chart{
  width: 99%;
}

.nav-tabs .nav-item .nav-link {
    background-color: transparent !important; /* Remove default background */
  }

  .nav-tabs .nav-item.active .nav-link {
    background-color: red !important; /* Add your custom background color */
    color: #ffffff !important; /* Text color for the active tab */
  }

.row{
  // margin-top:100px;
}

.custom-carousel .carousel-indicators {
  display: none !important;
}
.slider{
  width: 100%;
}

.slick-slide slick-active slick-current{
  width: 100%
}

.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}
.slick-track{
  width: 100%
}

.mainPagination{
  font-size: 12px;
}


.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}

.b-tabs .nav-item .nav-link {
  background-color: rgb(80, 80, 86) !important;
  color: white;
}
.b-tabs .nav-link.active {
  background-color: rgb(99, 99, 113) !important;
}

.light-card__wrapper {
  padding: 0 0px 15px 0px;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: white !important;
  padding: 0px;
  border-radius: 7px;
  border: 1px solid #DDE0E5;
}
.card-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.upcomingLowest{
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-evenly;
}

.barPieChart{
  display: flex;
}

.main__title{
  margin-bottom: 30px;
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
}
 .hide {
  display: none;
 }

.buttonTime{
  background: transparent linear-gradient(52deg, #262E6C 0%, #9B2A5C 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
    color: #f4f1ed;
    border-color: #262E6C;
}

.buttons{
  width: 50%
}
 .light-card {
        overflow-wrap: anywhere;
        border-right:1px solid #DDE0E5;
        border-bottom:1px solid #DDE0E5;
        min-height: 160px;
  }

  .team_table{
    box-shadow: none;
    background-color: white;
  }

@media screen and (min-width: 1400){
  .chart{
    width: 100% !important;
  }
}

@media screen and (min-width: 1341px) and (max-width: 1650px){
  .card-content h6{
    font-size: 13px;
  }
  .tableicons img{
    width: 70px !important;
  }
}

 @media screen and (max-width: 1340px) {
  .main {
    padding: 80px 10px 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table{
      width: 90%;
    }

    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination{
      font-size: 12px;
      width: 90%;
    }

    .tableicons img{
      width: 80px;
    }
  }
  .stateChart{
    width: 100%;
    display: block;
  }
  .line{
    width: 90%;
    margin-top: 30px;
    margin-left: 50px;
  }
  .tableLeads{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .light-card__wrapper {
    padding: 0 0px 15px 0px;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: white !important;
    padding: 0px;
    border-radius: 7px;
    border: 1px solid #DDE0E5;
  }
}

@media screen and (max-width: 1300px){
  .main{
    padding: 140px 39px 0px 40px !important;
  }

  .light-card__wrapper {
  padding: 0 0px 15px 0px;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: white !important;
  padding: 0px;
  border-radius: 7px;
  border: 1px solid #DDE0E5;
}
}

@media screen and (max-width: 1100px){
  .main{
    padding: 140px 39px 0px 40px !important;
  }

  .light-card__wrapper {
    padding: 0 0px 15px 0px;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

 @media screen and (max-width: 800px) {
  .main {
    padding: 130px 25px 0px 25px !important;
    .container {
      .light-card__wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
        margin-left: 8px;
        margin-right: 8px;
      }

      .bar-pie-chart{
        display: block;
      }

      .light-card {
        overflow-wrap: anywhere;
      }
    }
    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .main__title{
      margin-bottom: 10px;
      font-size: 30px;
      margin-top: 50px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1340px) {
  .main {
    padding: 180px 0 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table {
      width: 90%;
    }

    .upcomingLowest {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination {
      font-size: 12px;
      width: 90%;
    }
  }
}

@media screen and (max-width: 1190px){
  .main{
    padding: 130px 40px 0 40px !important;
  }
}
.active-button {
  background: transparent linear-gradient(52deg, #f7f7f7 0%, #fbfbfb 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
    color: #262E6C !important;
    border-color: black;
}

</style>
