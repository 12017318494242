import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.userManagementDomain;
const domain2 = api.stockManagementDomain;

const state = {
  status: false,
  teams: [],
  filteredTeams: [],
  teamsOrders: [],
  articles: [],
  articlesMaterials: [],
  teamOverview: {},
  freeSewers: [],
  freePatronists: [],
  freeHandSewers: [],
  freeTeamLeaders: [],
  dropdownTeamName: [],
  freeUsers: [],
  allFreeUsers: [],
  userByRole: [],
  teamVacationOverview: [],
  employeeVacation: [],
  currentPage: 1,
  totalItems: 0,
  teamsNames: [],
};

const getters = {
  getTeams: (state) => {
    return state.teams;
  },
  getEmployeeVacation: (state) => {
    return state.employeeVacation
  },
  getTeamVacationOverview: (state) => {
    return state.teamVacationOverview
  },
  getTeamsForPassiveStuck: (state) => {
    // state.teams.unshift('ALL');
    return state.teams;
  },
  getTeamsNames: (state) => {
    return state.teamsNames
  },
  getFilteredTeams: (state) => {
    return state.filteredTeams;
  },
  getTeamOverview: (state) => {
    return state.teamOverview;
  },

  getTeamOrders: (state) => {
    return state.teamsOrders;
  },

  getArticles: (state) => {
    return state.articles;
  },
  getArticlesMaterials: (state) => {
    return state.articlesMaterials;
  },
  getFreeTeamLeaders: (state) => {
    return state.freeTeamLeaders;
  },
  getFreeUsers: (state) => {
    return state.freeUsers;
  },
  getAllFreeUsers: (state) => {
    return state.allFreeUsers;
  },
  getUsersByRole: (state) => {
    return state.userByRole
  },
  getCurrentPageForTeams: (state) => {
    return state.currentPage;
  },

  //   getFilteredUsers: (state) => {
  //     return state.fileteredUsers;
  //   },

  getTotalItemsForTeams: (state) => {
    return state.totalItems;
  },
  getDropdownTeamName: (state) => {
    return state.dropdownTeamName
  },

};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_EMPLOYEE_VACATION(state, payload) {
    state.employeeVacation = payload;
  },
  SET_TEAM_VACATION_OVERVIEW(state, payload) {
    state.teamVacationOverview = payload;
  },
  SET_TEAMS(state, latest) {
    state.teams = latest;
  },
  SET_USERS_BY_ROLE(state, latest) {
    state.userByRole = latest
  },
  SET_FILTERED_TEAMS(state, latest) {
    state.filteredTeams = latest;
  },
  SET_ARTICLES(state, latest) {
    state.articles = latest;
  },
  SET_ARTICLES_MATERIALS(state, latest) {
    state.articlesMaterials = latest;
  },
  SET_TEAM_ORDERS(state, latest) {
    state.teamsOrders = latest;
  },
  SET_TEAM_OVERVIEW(state, latest) {
    state.teamOverview = latest;
  },
  SET_FREE_USERS_BY_ROLE(state, latest) {
    state.freeUsers = latest;
  },
  SET_ALL_FREE_USERS_BY_ROLE(state, latest) {
    state.allFreeUsers = latest;
  },
  SET_FREE_TEAM_LEADERS(state, latest) {
    state.freeTeamLeaders = latest;
  },
  //   SET_FILTERED_USERS(state, latest) {
  //     state.fileteredUsers = latest;
  //   },
  SET_TOTALITEMS_TEAMS(state, latest) {
    state.totalItems = latest;
  },
  SET_DROPDOWN_TEAMNAME(state, latest) {
    state.dropdownTeamName = latest
  },
  SET_TEAMS_NAMES(state, latest) {
    state.teamsNames = latest
  },
};
const actions = {
  async getTeamsPagination({ commit }, { pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getTeamNamesPagination`, {
        params: {
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TEAMS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_TEAMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async teamNames({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/team_names_active_orders`);
      commit('SET_TEAMS_NAMES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },

  async dropdown_TeamNames({ commit }, numberOfEntries) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dropdown_TeamNames?numberOfEntries=${numberOfEntries}`);
      commit('SET_DROPDOWN_TEAMNAME', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async usersByRole({ commit }, { personRole }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/users_by_Role?personRole=${personRole}`);
      commit('SET_USERS_BY_ROLE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getOrderItemsManagementBy({ commit }, { orderNr, id }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/orderItemsManagementBy/${orderNr}/${id}`);
      commit('SET_ARTICLES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getOrderItemsManagementByMaterials({ commit }, { orderNr, id }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/material_orderItemsManagementBy/${orderNr}/${id}`);
      commit('SET_ARTICLES_MATERIALS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetGetOrderItemsManagementBy({ commit }) {
    commit('SET_ARTICLES', []);
  },
  async resetGetOrderItemsManagementByMaterials({ commit }) {
    commit('SET_ARTICLES_MATERIALS', []);
  },
  async getOrderById({ commit }, { id, salesType }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/ordersBy/${id}?salesType=${salesType}`);
      commit('SET_TEAM_ORDERS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resergetOrderById({ commit }) {
    commit('SET_TEAM_ORDERS', [])
  },
  async employeeVacation({ commit }, {
    exactDate, startDate, endDate, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/employeeVacations`, {
        params: {
          exactDate,
          startDate,
          endDate,
          year,
        },
      });

      commit('SET_EMPLOYEE_VACATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async teamVacationOverview({ commit }, {
    teamNameId, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/team_vacations_Overview`, {
        params: {
          teamNameId,
          year,
        },
      });

      commit('SET_TEAM_VACATION_OVERVIEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async teamByTeamNameId({ commit }, teamNameId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/teamOverviewByTeamNameId?teamNameId=${teamNameId}`);
      commit('SET_TEAM_OVERVIEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },

  async addTeam({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object)
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addTeam`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true)
      successCallback()
      Swal.fire({
        icon: 'success',
        title: 'Team added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid team data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFreeTeamLeadersPagination({ commit }, { pageNumber, pageSize } = {}) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFreeTeamLeadersPagination`, {
        params: {
          pageNumber,
          pageSize,
        },
      })
      commit('SET_FREE_TEAM_LEADERS', response.data.items)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFreeUsersByRolePagination({ commit }, { personRole, pageNumber, pageSize } = {}) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFreeUsersByRolePagination`, {
        params: {
          personRole,
          pageNumber,
          pageSize,
        },
      })
      commit('SET_FREE_USERS_BY_ROLE', response.data.items)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadAllFreeUsersByRolePagination({ commit }, { personRole }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/all_free_users_by_role`, {
        params: {
          personRole,
        },
      })
      commit('SET_ALL_FREE_USERS_BY_ROLE', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async editTeam({ commit }, { teamNameId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editTeamByTeamNameId?teamNameId=${teamNameId}`, object);
      commit('SET_STATUS', true);
      successCallback()
      Swal.fire({
        icon: 'success',
        title: 'Team edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid team data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async getTeamNameByNameLike({ commit }, teamName) {
    if (teamName == '') {
      commit('SET_FILTERED_TEAMS', []);
      return;
    }
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getTeamNamesByNameLike?teamName=${teamName}`);
      commit('SET_FILTERED_TEAMS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async assignOrderItemToTeamLeader({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.put(`${domain2}/assignOrderItemToTeam`, object);
      commit('SET_STATUS', true);
      const newState = response.data.newState;
      successCallback();
      if (newState === 'Assigned') {
        Swal.fire({
          icon: 'success',
          title: `Order item changed to state: ${newState}`,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: `Order item changed to state: ${newState}`,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (error) {
      // Handle error response
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
