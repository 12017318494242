import * as signalR from '@microsoft/signalr';
import store from '../store/index';
import { api } from '../domainConfig';

const domain = api.stockManagementDomainNotification

const connection = new signalR.HubConnectionBuilder()
  .withAutomaticReconnect()
  .withUrl(`${domain}/hubs/notifications`, {
    accessTokenFactory: () => {
      // return store.state.authentication.loggedInUser;
      const loggedInUser = store.state.authentication.loggedInUser;

      if (loggedInUser && loggedInUser.token) {
        return loggedInUser.token;
      }
      // console.error('Bearer token is null or not present');
      return null;
    },
    transport: signalR.HttpTransportType.WebSockets,
    skipNegotiation: true,
  })
  .build();
// console.log('SignalrFile', connection)

// invoke hub methods aka send notification to hub
// function newWindowLoadedOnClient() {
//   connectionUserCount.invoke('NewWindowLoaded').then((value) => { return console.log(value) });
// }

// to invoke.


export default connection;
