import axios from 'axios';
// import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.stockManagementDomain;

const state = {
  orderTrackItems: [],
  currentPage: 1,
  totalItems: 0,
  orderTrackPDF: [],
};

const getters = {
  getOrderTrackItems: (state) => {
    return state.orderTrackItems;
  },
  getCurrentPageForOrderTrackItems: (state) => {
    return state.currentPage;
  },
  getTotalItemsForOrderTrackItems: (state) => {
    return state.totalItems;
  },
  getOrderTrackPDF: (state) => {
    return state.orderTrackPDF;
  },
};

const mutations = {
  SET_ORDER_TRACK_ITEMS(state, payload) {
    state.orderTrackItems = payload;
  },
  SET_ORDER_TRACK_ITEMS_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_ORDER_TRACK_ITEMS_TOTAL_ITEMS(state, payload) {
    state.totalItems = payload;
  },
  SET_ORDER_TRACK_PDF(state, payload) {
    state.orderTrackPDF = payload;
  },
};

const actions = {
  async loadOrderTrackByOnAndAn({ commit }, {
    orderNumber, orderItemId, pageNumber, pageSize, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/trackAndTraceByOrderNumber/${orderNumber}`, {
        params: {
          orderItemId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ORDER_TRACK_ITEMS', response.data.items);
      commit('SET_ORDER_TRACK_ITEMS_CURRENT_PAGE', response.data.currentPage);
      commit('SET_ORDER_TRACK_ITEMS_TOTAL_ITEMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async loadOrderTrackPDF({ commit }, {
    orderNumber, orderItemId, pageNumber, pageSize, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/trackAndTraceByOrderNumber/${orderNumber}`, {
        params: {
          orderItemId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ORDER_TRACK_PDF', response.data);
      commit('SET_ORDER_TRACK_ITEMS_CURRENT_PAGE', response.data.currentPage);
      commit('SET_ORDER_TRACK_ITEMS_TOTAL_ITEMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
