import axios from 'axios'
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.masterDataDomain;

const state = {
  status: false,
  suppliers: [],
  filteredSuppliersBySN: [],
  currentPage: 1,
  totalItems: 0,
  filteredSuppliersByCN: [],
  filteredSuppliersInSelect: [],
  purchaseCostCardBySupplier: [],
  supplierDropDown: [],
};

const getters = {
  getSuppliers: (state) => {
    return state.suppliers;
  },
  getFilteredSuppliersBySN: (state) => {
    return state.filteredSuppliersBySN;
  },
  getFilteredSuppliersByCN: (state) => {
    return state.filteredSuppliersByCN;
  },
  getCurrentPageForSuppliers: (state) => {
    return state.currentPage;
  },
  getFilteredSupplierInSelect: (state) => {
    return state.filteredSuppliersInSelect;
  },
  getTotalItemsForSuppliers: (state) => {
    return state.totalItems;
  },
  getCostCardBySupplier: (state) => {
    return state.purchaseCostCardBySupplier
  },
  getSupplierDropDown: (state) => {
    return state.supplierDropDown
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_SUPPLIERS(state, latest) {
    state.suppliers = latest;
  },
  SET_FILTERED_SUPPLIERS_BY_SN(state, latest) {
    state.filteredSuppliersBySN = latest;
  },
  SET_FILTERED_SUPPLIERS_BY_CN(state, latest) {
    state.filteredSuppliersByCN = latest;
  },
  SET_FILTERED_SUPPLIERS_IN_SELECT(state, latest) {
    state.filteredSuppliersInSelect = latest;
  },
  SET_TOTALITEMS_SUPPLIERS(state, latest) {
    state.totalItems = latest;
  },
  SET_COST_CARD_BY_SUPPLIER(state, latest) {
    state.purchaseCostCardBySupplier = latest
  },
  SET_SUPPLIER_DROP_DOWN(state, latest) {
    state.supplierDropDown = latest
  },
};

const actions = {
  async loadSuppliers({ commit }, {
    pageNumber, pageSize, supplierName, contactName, isActive,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/suppliers`, {
        params: {
          pageNumber,
          pageSize,
          supplierName,
          contactName,
          isActive,
        },
      });
      commit('SET_SUPPLIERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_SUPPLIERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addSupplier({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object)
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addSupplier`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async activateSupplier({ commit }, { supplierId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/activate_supplier/id?supplierId=${supplierId}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier activated succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editSupplier({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editSupplierById?id=${object.supplierId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async filterSuppliersBySN({ commit }, { supplierName, isActive }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/suppliersByNameLike?isActive=${isActive}&supplierName=${supplierName}`);
      commit('SET_FILTERED_SUPPLIERS_BY_SN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async suppliersDropDown({ commit }, { isActive }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/suppliers_drop_down?isActive=${isActive}`);
      commit('SET_SUPPLIER_DROP_DOWN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async costCardBySupplier({ commit }, { from, to, supplierId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/purchase_cost_card_by_supplier?from=${from}&to=${to}`, {
        params: {
          supplierId,
        },
      });
      commit('SET_COST_CARD_BY_SUPPLIER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredSupplierBySN({ commit }) {
    commit('SET_FILTERED_SUPPLIERS_BY_SN', []);
  },
  async filterSuppliersByCN({ commit }, contactName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/suppliersByContactNameLike?contactName=${contactName}`);
      commit('SET_FILTERED_SUPPLIERS_BY_CN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredSupplierByCN({ commit }) {
    commit('SET_FILTERED_SUPPLIERS_BY_CN', []);
  },
  async deactivateSupplierById({ commit }, { oldId, id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/deactivateSupplier/id?supplierId=${oldId}&newSupplierId=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier deactivated succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadFilSByName({ commit }, { companyName, isActive }) {
    try {
      const response = await axios.get(`${domain}/getSuppliersNameLike?isActive=${isActive}&companyName=${companyName}`);
      commit('SET_FILTERED_SUPPLIERS_IN_SELECT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
