var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stateChart"},[_c('div',{staticClass:"light-card__wrapper"},_vm._l((_vm.getOrdersGrouppedByState),function(item,key){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:key,staticClass:"light-card",class:{
        rejected: item.orderItemState === 'StockNotAvailable' || item.orderItemState === 'QualityCheckNotPassed' || item.orderItemState === 'Cancelled',
        finished: item.orderItemState === 'QualityPassed' && item.count>0,
        hide: (_vm.getLoggedInUser.role == 'TeamLeader' && item.orderItemState == 'Created') || (_vm.getLoggedInUser.role == 'Deliverer' && item.orderItemState !=='ReadyToDeliver' && item.orderItemState !== 'Delivered'),
        blue2: item.orderItemState === 'Confirmed' || item.orderItemState === 'Created' || item.orderItemState === 'Assigned',
        green2: item.orderItemState === 'InProduction' || item.orderItemState === 'InSewing' || item.orderItemState === 'QualityPassed' || item.orderItemState === 'ReadyToDeliver' || item.orderItemState === 'Delivered' || item.orderItemState === 'InHandSewing',
        red2: item.orderItemState === 'StockNotAvailable' || item.orderItemState === 'QualityCheckNotPassed' || item.orderItemState === 'Cancelled',
      },attrs:{"title":_vm.getLanguage === 'en' ? `${_vm.$t('ClickFor')} ${_vm.$t(item.orderStateDisplayName)} ${_vm.$t('Details')}` : `${_vm.$t('ClickForDetails')} ${_vm.$t(item.orderStateDisplayName)}`},on:{"click":function($event){return _vm.resetOrdersOverview()}}},[_c('router-link',{staticStyle:{"text-decoration":"none","display":"flex","flex-direction":"row-reverse","align-items":"center","justify-content":"space-between","width":"100%"},attrs:{"disabled":_vm.getLoggedInUser.role == 'Sewer' && item.orderItemState == 'Created',"event":_vm.getLoggedInUser.role == 'Sewer' && item.orderItemState == 'Created' ? '' : 'click',"to":_vm.getLoggedInUser.role == 'Deliverer' ? '/deliverer-orders-management/' + item.orderItemState : '/orders-overview/' + item.orderItemState}},[_c('span',{staticClass:"light-card__number",class:{
            blue: item.productInStockState === 'Confirmed' || item.productInStockState === 'Created' || item.productInStockState === 'Assigned',
            green: item.productInStockState === 'InProduction' || item.productInStockState === 'InSewing' || item.productInStockState === 'QualityPassed' || item.productInStockState === 'ReadyToDeliver' || item.productInStockState === 'Delivered',
            red: item.productInStockState === 'StockNotAvailable' || item.productInStockState === 'QualityCheckNotPassed',
          },staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(key + 1))]),_c('img',{attrs:{"src":require(`@/assets/images/DrenushaXharra/Dashboard/imgXharra-${_vm.getLoggedInUser.role == 'TeamLeader'? key+=1 : _vm.getLoggedInUser.role == 'Deliverer'? key+=7 : key+1}.png`),"alt":""}}),_c('div',{staticClass:"card-content"},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t(`${item.orderStateDisplayName}`))+" ")]),_c('h4',{attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('ICountUp',{attrs:{"end-val":item.count,"options":_vm.options1}})],1)])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }