<template>
  <section
    class="main"
  >
    <div
      class="filtering"
      style="padding-bottom: 10px;display:flex;align-items:center;gap:5px;"
    >
      <p style="margin-bottom:0px;">
        {{ $t("Year") }} :
      </p>
      <vue-select
        v-model="yearCounter"
        style="width:220px"
        :clearable="false"
        :options="yearsArray"
        :placeholder="$t('Select')"
      />
    </div>
    <div>
      <HighStateTypesDashboardStats />
    </div>
    <div
      class="stateChart"
    >
      <HighStateTypesDashboardStates
        :year-counter="yearCounter"
        @yearChanged="updateYearDashboard"
      />
    </div>
    <div>
      <div
        v-show="visibleDiv === 'monthly'"
        class="tableLeads"
      >
        <HighStateTypesDashboardTable />
      </div>
      <div style="display: flex; justify-content: center; align-items: center;">
        <b-modal
          id="show-birthday"
          ref="modal"
          v-model="showModal"
          body-class="headerModali"
          title="Birthday"
          hide-footer
          hide-header
          size="l"
        >
          <!-- @hide="modalHidden" -->
          <div class="allContentModal">
            <h1 style="font-size: 35px; font-weight: 700; color: #07145C;">
              Welcome {{ getLoggedInUser.firstName }}
            </h1>
            <div class="twoModals">
              <div
                class="modal-content"
                style="border-radius: 8px 0px 0px 0px;"
              >
                <div style="padding-top:10px;padding-bottom:10px;display: flex;justify-content:center;flex-direction: column;align-items: center;">
                  <img
                    class="birthdayimg"
                    src="@/assets/images/cake.svg"
                  >
                  <h4
                    v-if="isBirthdayToday.birthdays.length === 0"
                    style="color: white; font-size: 25px; font-weight: 900; margin-top: 15%"
                  >
                    No Birthdays today
                  </h4>
                  <p
                    v-if="isBirthdayToday.isToday && isBirthdayToday.birthdays.length === 1"
                    style="color:white; font-size: 12px; font-weight: 400; margin-top: 10%; margin-bottom: 0px"
                  >
                    Happy Birthday to
                  </p>
                  <p
                    v-for="person in isBirthdayToday.birthdays"
                    :key="person.fullName"
                    style="color: white; font-size: 20px; font-weight: 900; margin-bottom: 0px"
                  >
                    {{ person.fullName }}
                  </p>
                  <p
                    v-if="isBirthdayToday.isToday && isBirthdayToday.birthdays.length === 1"
                    style="color: white; font-weight: 400;font-size: 15px; text-align: center;"
                  >
                    Today is {{ isBirthdayToday.birthdays[0].fullName }} birthday, make sure to congratulate them!
                  </p>
                </div>
                <div class="modal-text">
                  <div>
                    <!-- <p
                v-if="isBirthdayToday.isToday && isBirthdayToday.birthdays.length > 1"
                style="color: white; font-weight: bold; font: 15px;"
              >
                Happy Birthday to
                <ul style="list-style-type: none; padding-left: 0;">
                  <li
                    v-for="person in isBirthdayToday.birthdays"
                    :key="person.fullName"
                    style="color: #ff274f;"
                  >
                    {{ person.fullName }}
                  </li>
                </ul>
              </p> -->


                  </div>
                  <!-- <div>
                <p
                  v-if="isBirthdayTomorrow.isTomorrow && isBirthdayTomorrow.birthdays.length > 1"
                  style="color: white; font-weight: bold;font-size: 15px;"
                >
                  Birthdays tomorrow:
                  <ul style="list-style-type: none; padding-left: 0;">
                    <li
                      v-for="person in isBirthdayTomorrow.birthdays"
                      :key="person.fullName"
                      style="color: #ff274f;"
                    >
                      {{ person.fullName }}
                    </li>
                  </ul>
                </p>

                <p
                  v-else-if="isBirthdayTomorrow.isTomorrow && isBirthdayTomorrow.birthdays.length === 1"
                  style="color: white; font-weight: bold;font-size: 15px;padding-left: 30px;"
                >
                  Tomorrow is <span style="color: #ff274f;">{{ isBirthdayTomorrow.birthdays[0].fullName }}</span> Birthday!
                </p>
              </div> -->
                </div>
              </div>
              <div
                class="modal-content-pick"
                style="border-radius: 0px 8px 0px 0px;"
              >
                <div style="padding-top:10px;padding-bottom:10px;display: flex;justify-content:center;flex-direction: column;align-items: center;">
                  <img
                    class="birthdayimg"
                    src="@/assets/images/pickUpPopUp.svg"
                  >
                  <h3 style="color: white; font-size: 25px; font-weight: 900; margin-top: 6%">
                    01:32
                  </h3>
                  <h4 style="color: white; font-size: 25px; font-weight: 900;">
                    Until the pick up
                  </h4>
                  <p style="color: white; font-size: 15px; font-weight: 400;">
                    The pickup for Dua's dress will accrue in 01:32 min
                  </p>
                </div>
              </div>
            </div>
            <div class="twoModals">
              <div
                class="modal-content-pick"
                style="border-radius: 0px 0px 0px 8px;"
              >
                <div style="padding-top:10px;padding-bottom:10px;display: flex;justify-content:center;flex-direction: column;align-items: center;">
                  <img
                    class="birthdayimg"
                    src="@/assets/images/suitcase.svg"
                  >
                  <h1 style="color:white; font-size: 40px; font-weight: 700; margin-top: 2%; margin-bottom: 2%">
                    {{ getEmployeesOnVacationCounter }}
                  </h1>
                  <p
                    style="color:white; font-size: 15px; font-weight: 400; margin-top: 2%;"
                  >
                    Employees on vacation!
                  </p>
                </div>
              </div>
              <div
                class="modal-content"
                style="border-radius: 0px 0px 8px 0px;"
              >
                <div style="padding-top:10px;padding-bottom:10px;display: flex;justify-content:center;flex-direction: column;align-items: center;">
                  <h1 style="color:white; font-size: 94px; font-weight: 700; margin-top: 0%; margin-bottom: 2%">
                    {{ getTotalNumberItemsByDate }}
                  </h1>
                  <h2 style="color:white; font-size: 30px; font-weight: 700; margin-bottom: 10%">
                    New Orders
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>

      <b-modal
        id="show-birthday-client"
        ref="modal"
        v-model="showModal1"
        body-class="headerModali"
        centered
        title="Birthday Client"
        hide-footer
        hide-header
        size="l"
      >
        <!-- @hide="modalHiddenClient" -->
        <div class="modal-content">
          <div style="padding-top:10px;padding-bottom:10px;display: flex;justify-content:center;flex-direction: column;align-items: center;">
            <img
              class="birthdayimg"
              src="@/assets/images/cake.svg"
            >
            <h1 style="color:white; font-family: 'Architects Daughter', cursive;">
              Happy Birthday Client
            </h1>
          </div>
          <div class="modal-text">
            <div>
              <p
                v-if="isBirthdayTodayClient.isTodayClient && isBirthdayTodayClient.clientBirthdayss.length > 1"
                style="color: white; font-weight: bold; font: 15px;"
              >
                Happy Birthday to
                <ul style="list-style-type: none; padding-left: 0;">
                  <li
                    v-for="person in isBirthdayTodayClient.clientBirthdayss"
                    :key="person.fullName"
                    style="color: #ff274f;"
                  >
                    {{ person.fullName }}
                  </li>
                </ul>
              </p>

              <p
                v-else-if="isBirthdayTodayClient.isTodayClient && isBirthdayTodayClient.clientBirthdayss.length === 1"
                style="color: white; font-weight: bold;font-size: 15px; padding-left: 30px;"
              >
                Today is <span style="color: #ff274f;">{{ isBirthdayTodayClient.clientBirthdayss[0].fullName }}</span> birthday, make sure to congratulate them!
              </p>
            </div>
          </div>
        </div>
      </b-modal>

    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { client } from '@/domainConfig'
import moment from 'moment'
import HighStateTypesDashboardStats from './mainComponents/HighStateTypesDashboardStats.vue'
import connection from '../../../microsoft-signalR/signalR';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import HighStateTypesDashboardStates from './mainComponents/HighStateTypesDashboardStates.vue'
import HighStateTypesDashboardTable from './mainComponents/HighStateTypesDashboardTable.vue'


export default {
  components: {
    HighStateTypesDashboardStates,
    HighStateTypesDashboardTable,
    HighStateTypesDashboardStats,
  },
  data() {
    return {
      yearCounter: moment().format('YYYY'),
      client: client.clientName,
      presentTime: moment().startOf('day').format('MM-DD'),
      birthdays: [],
      clientBirthdayss: [],
      showModal1: false,
      showModal: false,
      loading: false,
      orderGroup: {},
      rating: 7,
      showPieChart: false,
      itemsPerPage: 4,
      buttons: [
        { text: this.$t('Daily'), days: 1 },
        { text: this.$t('Week'), days: 7 },
        { text: this.$t('Month'), days: 30 },
      ],
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        arrows: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      currentPage: 1,
      selectedOrderState: this.$t('All'),
      pickedDate: null,
      year: 2024,
      yearsArray: [],
      options1: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      visibleDiv: 'monthly',
      pageNumber: 1,
      pageSize: 4,
      stateOptions: [
        { value: null, label: this.$t('All') },
        { value: 'Created', label: this.$t('Created') },
        { value: 'Assigned', label: this.$t('Assigned') },
        { value: 'Confirmed', label: this.$t('Confirmed') },
        { value: 'InProduction', label: this.$t('InProduction') },
        { value: 'InSewing', label: this.$t('InSewing') },
        { value: 'InHandSewing', label: this.$t('InHandSewing') },
        { value: 'QualityPassed', label: this.$t('QualityPassed') },
        { value: 'ReadyToDeliver', label: this.$t('ReadyToDeliver') },
        { value: 'Delivered', label: this.$t('Delivered') },
        { value: 'StockNotAvailable', label: this.$t('StockNotAvailable') },
        { value: 'QualityCheckNotPassed', label: this.$t('QualityCheckNotPassed') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getTotalNumberItemsByDate', 'getDashboardOrders', 'getEmployeesOnVacationCounter', 'getClientBirthdays', 'getDashboardOrders', 'getEmployeeBirthdays', 'getLanguage', 'getdashboardCounterItems', 'getUsers', 'getFeedbackForDashboard', 'getClientCounter', 'getStockMaterialVariantReporting', 'getOrdersGrouppedByStateDashboard', 'getOrdersGrouppedByState', 'getLoggedInUser', 'getIsLoading', 'getUpComingPickUpDates', 'getTotalItemsForOrders', 'getMostSoldProducts']),
    isBirthdayToday() {
      const today = moment().startOf('day').format('MM-DD');
      const birthdaysToday = this.birthdays.filter((birthday) => { return birthday.date === today });

      if (birthdaysToday.length > 0) {
        return { isToday: true, birthdays: birthdaysToday };
      }

      return { isToday: false, birthdays: [] };
    },

    isBirthdayTomorrow() {
      const tomorrow = moment().add(1, 'day').startOf('day').format('MM-DD');
      const birthdaysTomorrow = this.birthdays.filter((birthday) => { return birthday.date === tomorrow });

      if (birthdaysTomorrow.length > 0) {
        return { isTomorrow: true, birthdays: birthdaysTomorrow };
      }

      return { isTomorrow: false, birthdays: [] };
    },

    isBirthdayTodayClient() {
      const today = moment().startOf('day').format('MM-DD');
      const birthdaysToday = this.clientBirthdayss.filter((birthday) => { return birthday.date === today });

      if (birthdaysToday.length > 0) {
        return { isTodayClient: true, clientBirthdayss: birthdaysToday };
      }

      return { isTodayClient: false, clientBirthdayss: [] };
    },

    isBirthdayTomorrowClient() {
      const tomorrow = moment().add(1, 'day').startOf('day').format('MM-DD');
      const birthdaysTomorrow = this.clientBirthdayss.filter((birthday) => { return birthday.date === tomorrow });

      if (birthdaysTomorrow.length > 0) {
        return { isTomorrowClient: true, clientBirthdayss: birthdaysTomorrow };
      }

      return { isTomorrowClient: false, clientBirthdayss: [] };
    },
    colorByRate() {
      if (this.rating <= 3) {
        return 'red';
      }
      if (this.rating > 3 && this.rating <= 6) {
        return 'orange';
      }
      return 'green';
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getStockMaterialVariantReporting.slice(start, end);
    },
    paginatedDataForFeedback() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getFeedbackForDashboard.slice(start, end);
    },
  },
  watch: {
    selectedYear(value) {
      this.updateYearDashboard(value);
    },
    pageNumber(value) {
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate != null ? this.pickedDate : 7,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    yearCounter(value) {
      this.loadOrdersGrouppedByState({
        year: value,
      })
      this.dashboardOrders(value)
      this.dashboardCounterItemsAndProductInStock(value)
      this.$route.query.year = value
    },
    isBirthdayToday(newVal) {
      this.showModal = true;
      console.log(`Today is ${newVal.firstName}'s birthday!`);
    },

    isBirthdayTodayClient(newVal) {
      this.showModal1 = false;
      console.log(`Today is ${newVal.fullName}'s birthday!`);
    },

  },
  created() {
    this.connectToSignalR();
  },
  mounted() {
    this.fetch()
    this.most_Sold_Products()
    this.totalItemsByDate({ date: moment().format('YYYY-MM-DD') })
    this.employeesVacationCounter({ date: moment().format('YYYY-MM-DD') })
    this.fetchVacationCounter();
    this.dashboardOrders(this.yearCounter)
    const modalShownBefore = localStorage.getItem('modalShownBefore');
    if (!modalShownBefore) {
      this.upcomingDatess();
    }
    const modalShownBeforeClient = localStorage.getItem('modalShownBeforeClient');
    if (!modalShownBeforeClient) {
      this.upcomingBirthdaysClient();
    }

    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }
    this.clientBirthdays({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    })
    this.employeeBirthdays({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(2, 'days').format('YYYY-MM-DD'),
    })
    this.upcomingPickupDays({
      numberOfDays: 1,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    })
    this.loadClientCounter()
    this.dashboardCounterItemsAndProductInStock(this.yearCounter)
  },
  methods: {
    ...mapActions(['totalItemsByDate', 'clientBirthdays', 'loadOrdersGrouppedByState',
      'dashboard_Yearly_Graph',
      'dashboardOrders',
      'reporting_less_stockMaterialVariants', 'loadOrdersGrouppedByState', 'loadFeedbackForDashboard',
      'employeesVacationCounter',
      'changeLoadingtoTrue', 'resetOrdersOverview', 'upcomingPickupDays', 'loadOrdersGrouppedByState', 'most_Sold_Products', 'loadClientCounter', 'dashboardCounterItemsAndProductInStock', 'getUsersPagination', 'employeeBirthdays']),
    async fetchVacationCounter() {
      this.employeesVacationCounter({
        date: moment().format('YYYY-MM-DD'),
      })
      console.log(this.getEmployeesOnVacationCounter);
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    async upcomingDatess() {
      try {
        await this.employeeBirthdays({
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(2, 'days').format('YYYY-MM-DD'),
        })
          .then(() => {
            // console.log(res);
          })
        this.birthdays = this.getEmployeeBirthdays.map((item) => {
          const dateOfBirth = new Date(item.dateOfBirth);

          const formattedDate = moment(dateOfBirth).format('MM-DD');

          return {
            date: formattedDate,
            fullName: item.fullName,
          };
        });
        // this.showModal = true;
        localStorage.setItem('modalShownBefore', true);
      } catch (e) {
        console.log(e);
      }
    },
    async upcomingBirthdaysClient() {
      try {
        await this.clientBirthdays({
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(2, 'days').format('YYYY-MM-DD'),
          clientName: this.client,
        })
          .then((res) => {
            console.log(res);
          })
        this.clientBirthdayss = this.getClientBirthdays.map((item) => {
          const dateOfBirth = new Date(item.dateOfBirth);

          const formattedDate = moment(dateOfBirth).format('MM-DD');

          return {
            date: formattedDate,
            fullName: item.fullName,
          };
        });
        // this.showModal = true;
        localStorage.setItem('modalShownBeforeClient', true);
      } catch (e) {
        console.log(e);
      }
    },

    top10() {
      this.reporting_less_stockMaterialVariants()
    },
    goToOrder() {
      this.$router.push('orders-overview/all')
    },
    goToMaterials() {
      this.$router.push('inventory')
    },
    goToFeedbacks() {
      this.$router.push('employee-payments')
    },
    goToClients() {
      this.$router.push('clients')
    },
    goToProducts() {
      this.$router.push('products')
    },
    goToProductsInStock() {
      this.$router.push('products-in-stock')
    },
    feedbackForDashboard() {
      this.loadFeedbackForDashboard()
    },
    updateShowPieChart() {
      this.showPieChart = true; // Update showPieChart to true when PieChart is displayed
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      const totalPages = Math.ceil(this.getStockMaterialVariantReporting.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    prevPageFeedback() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPagefeedback() {
      const totalPages = Math.ceil(this.getFeedbackForDashboard.length / this.itemsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        // console.log('Connection to User Hub Successful');
      }
      function rejected() {
        //      console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        setTimeout(() => {
          connection.start().then(fulfilled, rejected);
        }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },

    disconnectFromSignalR() {
      connection.stop();
    },

    async fetch() {
      // this.changeLoadingtoTrue(false)

      try {
        await this.loadOrdersGrouppedByState({
          year: this.yearCounter,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    handleInput() {
      // Remove any non-digit characters
      this.year = this.year.replace(/\D/g, '');

      // Limit the year to 4 digits
      this.year = this.year.slice(0, 4);
    },
    showDiv(divId) {
      this.visibleDiv = divId;
    },
    pickUpDates(index) {
      this.activeButton = index; // Set the active button
      this.pickedDate = this.buttons[index].days; // Set the pickedDate
      this.upcomingPickupDays({
        numberOfDays: this.pickedDate, // Use the pickedDate value
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    updateYearDashboard(value) {
      this.yearDashboard = value;
    },
  },


};
</script>

<style lang="scss" scoped>

.allContentModal{
  width: 1000px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2%;
  padding-top: 5%;
  border-radius: 6px;
  position: relative;
  right: 200px;
}

.twoModals {
  display: flex;
}

.modal-content-pick {
  background: linear-gradient(177deg, rgba(226,230,254,1) 0%, rgba(36,47,110,1) 100%);
  border: none;
  padding: 20px;
  width: 50%
}

.birthdayimg{
  width: 100px;
  color:white;
}
.modal-text{
  display:flex;
  justify-content: center;
  gap: 30px;
}

.modal-dialog {
  margin: 0px;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerModali {
  padding:0px !important;
  background-color:blue !important;
}
.modal-content {
  background: linear-gradient(180deg, rgba(254,39,79,1) 0%, rgba(36,47,110,1) 100%);
  border: none;
  padding: 20px;
  box-shadow: none;
  width: 50%;
}
.close-button{
  color: white;
  width:30px !important;
  background:transparent;
}
.main{
  // padding: 70px 15px 0px 105px;
  display: flex;
  flex-direction: column;
}

.card-content h6{
  font-size: 15px;
}

.stateChart{
  width: 100%;
  display: flex;
}

.line{
  width: 50%;
}

.tableicons{
  text-align: center;
}

.tableicons img{
  width: 90px;
}

.blue{
  background-color: #242f6e;
  border-color: #242f6e;
  color: white;
 }

 .green{
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
 }
 .red{
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
 }

.tableLeads{
  display: flex;
  width: 100%;
}

.chart{
  width: 99%;
}

.nav-tabs .nav-item .nav-link {
    background-color: transparent !important; /* Remove default background */
  }

  .nav-tabs .nav-item.active .nav-link {
    background-color: red !important; /* Add your custom background color */
    color: #ffffff !important; /* Text color for the active tab */
  }

.row{
  // margin-top:100px;
}

.custom-carousel .carousel-indicators {
  display: none !important;
}
.slider{
  width: 100%;
}

.slick-slide slick-active slick-current{
  width: 100%
}

.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}
.slick-track{
  width: 100%
}

.mainPagination{
  font-size: 12px;
}


.slick-initialized .slick-slide[data-v-e4caeaf8]{
  width: 100%
}

.b-tabs .nav-item .nav-link {
  background-color: rgb(80, 80, 86) !important;
  color: white;
}
.b-tabs .nav-link.active {
  background-color: rgb(99, 99, 113) !important;
}

.light-card__wrapper {
  padding: 0 0px 15px 0px;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  margin-left: 8px;
  margin-right: 8px;
  grid-row-gap: 12px;
  background-color: white !important;
    padding: 22px;
    border-radius: 10px;
}
.upcomingLowest{
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-evenly;
}

.barPieChart{
  display: flex;
}

.main__title{
  margin-bottom: 30px;
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
}
 .hide {
  display: none;
 }
 .blue{
  background-color: #242f6e;
  border-color: #242f6e;
  color: white;
 }
//  .container{
//   margin: 10px;
//   padding: 0px;
//  }
 .blue2{
//  border-top: 4px solid #242f6e;
  // background: linear-gradient(356deg, #15E582 0%, #000000 100%);
  // background-clip: padding-box;
  border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #128EEE 70% , #424242 );
}
.green2{
//  border-top: 4px solid #0cb893;
 border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #15E582  70% , #424242 );
}

.buttonTime{
  background: transparent linear-gradient(52deg, #262E6C 0%, #9B2A5C 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
    color: #f4f1ed;
    border-color: #262E6C;
}

.buttons{
  width: 50%
}
.red2{
//  border-top: 4px solid #FF274F;
border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #F11313   70% , #424242 );
}
 .green{
  background-image: linear-gradient(135deg, #61d8be 0%, #1e8975 100%);
  border-color: transparent;
 }
 .red{
  background-image: linear-gradient(135deg, #eb3449 0%, #f35943 100%);
  border-color: transparent;
 }
 .light-card {
        overflow-wrap: anywhere;
  }

  .team_table{
    box-shadow: none;
    background-color: white;
  }

  .container {
    width: 100% !important;
  }

  .active-button {
    background: transparent linear-gradient(52deg, #f7f7f7 0%, #fbfbfb 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
    color: #262E6C !important;
    border-color: black;
  }

@media screen and (min-width: 1400){
  .chart{
    width: 100% !important;
  }
}

@media screen and (min-width: 1341px) and (max-width: 1650px){
  .card-content h6{
    font-size: 13px;
  }
  .tableicons img{
    width: 70px !important;
  }
  .main {
    padding: 80px 10px 0 90px !important;
  }
}

 @media screen and (max-width: 1340px) {
  .main {
    padding: 80px 10px 0 90px !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
    .team_table{
      width: 90%;
    }

    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .mainPagination{
      font-size: 12px;
      width: 90%;
    }

    .tableicons img{
      width: 80px;
    }
  }
  .stateChart{
    width: 100%;
    display: block;
  }
  .line{
    width: 90%;
    margin-top: 30px;
    margin-left: 50px;
  }
  .tableLeads{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 1190px){
  .main{
    padding: 90px 10px 0 10px !important;
  }
}

@media screen and (max-width: 800px) {
  .main {
    padding: 130px 25px 0px 25px !important;
    .container {
      .light-card__wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
        margin-left: 8px;
        margin-right: 8px;
      }

      .bar-pie-chart{
        display: block;
      }

      .light-card {
        overflow-wrap: anywhere;
      }
    }
    .upcomingLowest{
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    .main__title{
      margin-bottom: 10px;
      font-size: 30px;
      margin-top: 50px;
      text-align: center;
    }
  }
}

</style>
